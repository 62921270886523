import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { finalize, take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import * as _ from 'lodash';
import stringInfo from '../../../../translations/en-US.json';
import { SettingsService } from '@app/settings/settings.service';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { CustomsidenavComponent } from '@app/shared/modal/customsidenav/customsidenav.component';
import { ImportDocumentAndTemplateComponent } from '../import-document-and-template/import-document-and-template.component';
import { ArchivedBy } from '../archive-document/archive-document.component';
import { CreateDocumentComponent } from '../create-document/create-document.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { CreateTemplateComponent } from '../create-template/create-template.component';
import { AddReadersComponent } from '../add-readers/add-readers.component';
import { MatMenuTrigger } from '@angular/material/menu';

interface configOption {
  label: string;
  key: string;
}
interface ShowHideColumns {
  showType: boolean;
  showEditors: boolean;
  showReccurence: boolean;
  showApprovers: boolean;
  showFolder: boolean;
}
export interface ArchiveDocumentsResult {
  guid: string;
  title: string;
  version: string;
  created_by: ArchivedBy;
  allowed_actions_list: string[];
  archived_on_timestamp?: string;
  archived_by: ArchivedBy;
  document_type: string;
}

@Component({
  selector: 'app-attention-required',
  templateUrl: './attention-required.component.html',
  styleUrls: ['./attention-required.component.scss']
})
export class AttentionRequiredComponent implements OnInit {
  // [x: string]: any;
  @ViewChild('menuTrigger') filterTrigger: MatMenuTrigger;
  OrganisationID: string | null;
  activeDocTab: 'overview' | 'folders' = 'overview';
  selectedDocGuid: any;
  AttentionList: any = [];
  guidSubscription: any;
  error: any;
  DocumentDetails: any;
  DocURL: any;
  allowed_actions: any;
  manageEditors: boolean;
  editDocument: boolean;
  editComplete: boolean;
  searchText: any;
  public userList: Array<any> = [];
  search_title: any = '';
  status: any = [];
  status_filter: string = '';
  status_loading = false;
  Events: object[];
  documentStatus: any = [];
  documentOverallStatus: any;
  documentTabStatus: any;
  page = 1;
  pageSize = 10;
  pageText = '';
  totalLength: any;
  paginatedData: any;
  currentPage: number = 1;
  pagingNumber = 0;
  statusFilter: any = '';
  loaderArr: any = [];
  accArray: any[] = [];
  search_text: any = '';
  update_status: any;
  loading = false;
  orgGuidSubscription: any;
  placeholderSearch = stringInfo.Attention_Required.PlaceHolder_Search;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  paginatedDataUrl: any;
  spnning = false;
  show = true;
  role: any;
  userRole: any;
  key: any;
  statusAndActions: any;
  clickedGuid: any;
  status6: any;
  status5: any;
  status2: any;
  show_add_new_button: boolean = false;
  private nameColors = new Map<string, string>();
  showFolders = false;
  showHideColumns: ShowHideColumns = {
    showType: false,
    showReccurence: false,
    showApprovers: false,
    showEditors: false,
    showFolder: false
  };

  docFilterColOptions: configOption[] = [
    { label: 'Type', key: 'showType' },
    { label: 'Recurrence', key: 'showReccurence' },
    { label: 'Approvers', key: 'showApprovers' },
    { label: 'Editors', key: 'showEditors' },
    { label: 'Folder', key: 'showFolder' }
  ];
  roleRouteMapper = {
    '/organisation/documents/attention-required': '',
    '/organisation/documents/author': 'author',
    '/organisation/documents/editor': 'editor',
    '/organisation/documents/approver': 'approver'
  };
  titleMapper = {
    '': 'Attention_Required.Title',
    author: 'Author_Page.Header',
    editor: 'Editor_Page.Title',
    approver: 'Approver_Page.Title'
  };
  roleFilter: string = '';
  tabsData: any = [
    {
      title: 'MY DOCUMENTS',
      code: 'my-documents',
      total: 0,
      info: false,
      active: false,
      countCode: 'total',
      childCode: {
        value: 'All',
        key: ''
      }
    },
    {
      title: 'EDIT INVITATIONS',
      code: 'edit-invitations',
      total: 0,
      info: false,
      active: false,
      countCode: 'in_edit',
      childCode: {
        value: 'In Edit',
        key: 'in edit'
      }
    },
    {
      title: 'APPROVAL INVITATIONS',
      code: 'approval-invitations',
      total: 0,
      info: false,
      active: false,
      countCode: 'pending_approval',

      childCode: {
        value: 'Pending Approval',
        key: 'pending approval'
      }
    },
    {
      title: 'PUBLISHED',
      code: 'published',
      countCode: 'published',

      total: 0,
      info: false,
      active: false,
      childCode: 'published'
    }
  ];
  publishedStatus = [
    {
      value: 'All',
      key: '',
      isSelected: true
    },
    {
      value: 'Yours',
      key: 'yours',
      isSelected: false
    }
  ];
  recurrenceSchedules: any[] = [];
  document_types: any = [];
  selectedFilter = 'Recurrence';
  filtersMenu: any = [
    {
      title: 'Recurrence',
      active: true,
      show: true
    },
    {
      title: 'Type',
      active: false,
      show: true
    },
    {
      title: 'Folder',
      active: false,
      show: true
    },
    {
      title: 'Editor',
      active: false,
      show: true
    },
    {
      title: 'Author',
      active: false,
      show: true
    },
    {
      title: 'Approver',
      active: false,
      show: true
    }
  ];
  peopleList: any = [];
  folderList: any = [];
  editorSearchText = '';
  approverSearchText = '';
  authorSearchText = '';
  folderSearchText = '';
  customFilters: any = {};
  customFilterString = '';
  notificationCount: any = {};
  firstLoad = true;
  filterChanged = {
    Recurrence: false,
    Type: false,
    Folder: false,
    Editor: false,
    Author: false,
    Approver: false
  };
  filtersSelectedCount = 0;
  selectedDocumentTab = 'attention-required';
  apiEndPoints = {
    'attention-required': 'document',
    'published-document': 'document/published_docs',
    'archived-document': 'document/archive_docs',
    'ready-renewal': 'notifications'
  };
  apiEndPoint = 'document';
  pageSizes = [
    { value: 10, label: '10 / page' },
    { value: 25, label: '25 / page' },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' }
  ];
  statusCount: any = {};
  tabCount: any = {};
  statusCountStruct = {
    edit_complete: 0,
    draft: 0,
    in_edit: 0,
    published: 0,
    pending_approval: 0,
    approved: 0,
    change_requested: 0,
    total: 0,
    attention_notification_count: 0,
    published_notification_count: 0
  };
  constructor(
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private userservice: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private sidenavService: SidenavService,
    private toasterService: ToasterService
  ) {
    this.activatedRoute.params.subscribe((res: any) => {
      this.roleFilter = this.roleRouteMapper[window.location.pathname];
    });
  }
  resetFilterNoAPiCall() {
    this.recurrenceSchedules?.forEach(item => (item['recurrenceSelected'] = false));
    this.folderList?.forEach((item: any) => (item['folderSelected'] = false));
    this.document_types?.forEach((item: any) => (item['typeSelected'] = false));
    this.peopleList?.forEach((item: any) => (item['editorSelected'] = false));
    this.peopleList?.forEach((item: any) => (item['authorSelected'] = false));
    this.peopleList?.forEach((item: any) => (item['approverSelected'] = false));
    this.customFilters = {};
    this.filtersSelectedCount = Object.values(this.customFilters).filter((arr: any = []) => arr.length > 0).length;
    if (this.selectedDocumentTab === 'published-document') {
      this.publishedStatus = [
        {
          value: 'All',
          key: '',
          isSelected: true
        },
        {
          value: 'Yours',
          key: 'yours',
          isSelected: false
        }
      ];
    }
  }
  get checkCreateAccess() {
    return this.userservice._userDetails.user['role'].includes('document_author');
  }
  get createTemplateAccess() {
    return (
      this.userservice._userDetails.user['role'].includes('document_author') ||
      this.userservice._userDetails.user['role'].includes('organization_admin_role')
    );
  }
  changeDocumentTab(tab = 'attention-required') {
    for (let i = 0; i < this.documentStatus.length; i++) {
      if (this.documentStatus[i].key == '') {
        this.documentStatus[i]['isSelected'] = true;
      } else {
        this.documentStatus[i]['isSelected'] = false;
      }
    }
    this.publishedStatus = [
      {
        value: 'All',
        key: '',
        isSelected: true
      },
      {
        value: 'Yours',
        key: 'yours',
        isSelected: false
      }
    ];
    this.customFilterString = '';
    this.apiEndPoint = this.apiEndPoints[tab];
    this.selectedDocumentTab = tab;
    this.search_text = '';
    this.searchText = '';
    this.statusFilter = '';
    this.page = 1;
    this.pageText = '';
    this.resetFilterNoAPiCall(); // clear
    this.getAttentionList();
  }
  changePageSize(pageSize: any) {
    this.pageSize = pageSize;
    this.getAttentionList();
  }
  goToPageChange(value: number = 1) {
    this.page = value;
    this.getAttentionList();
  }
  pageChange(value: number = 1) {
    this.page = value;
    this.getAttentionList();
  }
  changeActiveDocumentTab(tab: 'overview' | 'folders' = 'overview') {
    this.activeDocTab = tab;
  }

  changeActiveTab(tab: any = {}) {
    this.searchText = '';
    this.page = 1;
    this.pageText = '';
    this.resetFilterNoAPiCall(); // clear
    this.tabsData.forEach((item: any) => {
      item['active'] = item['code'] === tab.code ? true : false;
    });

    if (tab.childCode !== 'published') {
      this.selectedDocumentTab = 'attention-required';
      this.apiEndPoint = this.apiEndPoints[this.selectedDocumentTab];
      if (tab['code'] == 'my-documents') {
        this.peopleList.forEach((item: any) => {
          if (this.userservice._userDetails.user['org_user_guid'] == item['guid']) {
            item['authorSelected'] = true;
          } else {
            item['authorSelected'] = false;
          }
        });
        this.statusFilter = '';
      } else if (tab['code'] == 'approval-invitations') {
        this.peopleList.forEach((item: any) => {
          if (this.userservice._userDetails.user['org_user_guid'] == item['guid']) {
            item['approverSelected'] = true;
          } else {
            item['approverSelected'] = false;
          }
        });
        this.statusFilter = 'pending approval';
      } else if (tab['code'] == 'edit-invitations') {
        this.peopleList.forEach((item: any) => {
          if (this.userservice._userDetails.user['org_user_guid'] == item['guid']) {
            item['editorSelected'] = true;
          } else {
            item['editorSelected'] = false;
          }
        });
        this.statusFilter = 'in edit';
      }
      for (let i = 0; i < this.documentStatus.length; i++) {
        if (this.documentStatus[i].key == this.statusFilter) {
          this.documentStatus[i]['isSelected'] = true;
        } else {
          this.documentStatus[i]['isSelected'] = false;
        }
      }
      this.applyCustomFilters(true);
    } else {
      this.changeDocumentTab('published-document');
      this.peopleList.forEach((item: any) => {
        if (this.userservice._userDetails.user['org_user_guid'] == item['guid']) {
          item['authorSelected'] = true;
        } else {
          item['authorSelected'] = false;
        }
      });
      this.statusFilter = '';
    }
  }
  checkTabStatus(tab: any) {
    if (tab.code == 'my-documents') {
      return this.checkAuthor;
    }
    if (tab.code == 'approval-invitations') {
      return this.checkApprover;
    }
    if (tab.code == 'edit-invitations') {
      return this.checkEditor;
    }
    if (tab.code == 'published') {
      return this.selectedDocumentTab == 'published-document';
    }
  }
  get checkEditor() {
    if (
      this.customFilters?.editor?.length == 1 &&
      this.customFilters.editor.includes(this.userservice._userDetails.user['org_user_guid']) &&
      this.statusFilter == 'in edit'
    )
      return true;
  }
  get checkAuthor() {
    if (
      this.customFilters?.author?.length == 1 &&
      this.customFilters.author.includes(this.userservice._userDetails.user['org_user_guid']) &&
      this.statusFilter == ''
    )
      return true;
  }
  get checkApprover() {
    if (
      this.customFilters?.approver?.length == 1 &&
      this.customFilters.approver.includes(this.userservice._userDetails.user['org_user_guid']) &&
      this.statusFilter == 'pending approval'
    )
      return true;
  }
  get checkPublishedAuthor() {
    if (
      this.customFilters?.author?.length == 1 &&
      this.customFilters.author.includes(this.userservice._userDetails.user['org_user_guid'])
    )
      return true;
  }
  setFilter(filter: any = {}) {
    this.filtersMenu.forEach((item: any) => {
      item['active'] = item['title'] === filter.title ? true : false;
    });
    this.selectedFilter = filter.title;
  }
  getPeopleList(): void {
    this.settingsService.getOrganizationPeopleList(this.OrganisationID).subscribe(
      (data: any) => {
        this.peopleList = data.data;
        this.peopleList.forEach((item: any) => {
          if (item.guid == this.userservice._userDetails.user['org_user_guid']) {
            item['last_name'] = item['last_name'] + ' (You)';
          }
        });
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getFolderList(): void {
    this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
      (data: any) => {
        this.folderList = data;
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getDocumentTypes() {
    this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
      this.document_types = data;
    });
  }
  getScheduleTypes() {
    this.orgAdminService.getOrganisationScheduleTypes(this.OrganisationID).subscribe(data => {
      const filteredSchedules = _.filter(data, schedule => {
        return schedule.name === 'SEMI_ANNUALLY' || schedule.name === 'EVERY_TWO_YEARS' || schedule.name === 'ANNUALLY';
      });
      this.recurrenceSchedules = _.map(filteredSchedules, schedule => {
        let display_value = schedule['value'].replace('-', ' ');
        display_value = display_value.charAt(0).toUpperCase() + display_value.slice(1).toLowerCase();
        return {
          display_value: display_value,
          value: schedule['name']
        };
      });
    });
  }
  openFolder() {
    this.showFolders = true;
  }
  applyCustomFilters(updateCount = false) {
    this.editorSearchText = '';
    this.approverSearchText = '';
    this.authorSearchText = '';
    this.folderSearchText = '';
    let filters = {};
    filters['recurrence'] = this.recurrenceSchedules
      ?.filter(item => item['recurrenceSelected'])
      .map(item => item['value']);
    filters['parent_folder'] = this.folderList
      ?.filter((item: any) => item['folderSelected'])
      .map((item: any) => item['guid']);
    filters['document_types'] = this.document_types
      ?.filter((item: any) => item['typeSelected'])
      .map((item: any) => item['slug']);
    filters['editor'] = this.peopleList?.filter((item: any) => item['editorSelected']).map((item: any) => item['guid']);
    filters['author'] = this.peopleList?.filter((item: any) => item['authorSelected']).map((item: any) => item['guid']);
    filters['approver'] = this.peopleList
      ?.filter((item: any) => item['approverSelected'])
      .map((item: any) => item['guid']);
    this.customFilters = JSON.parse(JSON.stringify(filters));
    this.filtersSelectedCount = Object.values(this.customFilters).filter((arr: any = []) => arr.length > 0).length;
    const queryString = this.objectToQueryString(filters);
    this.customFilterString = queryString;
    this.getAttentionList();
  }
  clearFilters() {
    this.resetFilterNoAPiCall(); // clear
    this.applyCustomFilters(true);
  }
  toggleMenu(triggerMenuName: string) {
    this.filtersMenu.forEach((item: any) => {
      item['active'] = item['title'] === triggerMenuName ? true : false;
    });
    this.selectedFilter = triggerMenuName;
    this.filterTrigger.openMenu();
  }
  noFilterAction() {
    this.editorSearchText = '';
    this.approverSearchText = '';
    this.authorSearchText = '';
    this.folderSearchText = '';
    const updateSelection = (list: any, filterKey: any, selectionKey: any, compareKey = 'guid') => {
      list?.forEach((item: any) => {
        item[selectionKey] = this.customFilters[filterKey]?.includes(item[compareKey]) || false;
      });
    };
    updateSelection(this.peopleList, 'editor', 'editorSelected');
    updateSelection(this.peopleList, 'author', 'authorSelected');
    updateSelection(this.peopleList, 'approver', 'approverSelected');
    updateSelection(this.folderList, 'parent_folder', 'folderSelected');
    updateSelection(this.document_types, 'document_types', 'typeSelected', 'slug');
    updateSelection(this.recurrenceSchedules, 'recurrence', 'recurrenceSelected', 'value');
  }
  objectToQueryString(obj: Record<string, unknown>): string {
    return Object.entries(obj)
      .filter(([_, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map(item => encodeURIComponent(String(item)));
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(encodedValues))}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
  }
  removeFilter(filter: string = '') {
    let filterOptionMapper = {
      editor: 'editorSelected',
      approver: 'approverSelected',
      author: 'authorSelected'
    };
    if (filter === 'editor' || filter === 'author' || filter === 'approver') {
      this.peopleList.forEach((item: any) => (item[filterOptionMapper[filter]] = false));
      if (filter === 'author' && this.selectedDocumentTab === 'published-document') {
        this.publishedStatus = [
          {
            value: 'All',
            key: '',
            isSelected: true
          },
          {
            value: 'Yours',
            key: 'yours',
            isSelected: false
          }
        ];
      }
    }
    if (filter === 'folder') {
      this.folderList.forEach((item: any) => (item['folderSelected'] = false));
    }
    if (filter === 'type') {
      this.document_types.forEach((item: any) => (item['typeSelected'] = false));
    }
    if (filter === 'recurrence') {
      this.recurrenceSchedules.forEach((item: any) => (item['recurrenceSelected'] = false));
    }
    this.applyCustomFilters(true);
  }
  get selectedApprover() {
    let approvers = this.peopleList
      .filter((item: any) => this.customFilters?.approver?.includes(item.guid) && item.approverSelected)
      .map((item: any) => item.first_name + item.last_name);
    return approvers;
  }
  get selectedEditor() {
    let editors = this.peopleList
      .filter((item: any) => this.customFilters?.editor?.includes(item.guid) && item.editorSelected)
      .map((item: any) => item.first_name + item.last_name);
    return editors;
  }
  get selectedAuthor() {
    let authors = this.peopleList
      .filter((item: any) => this.customFilters?.author?.includes(item.guid) && item.authorSelected)
      .map((item: any) => item.first_name + item.last_name);
    return authors;
  }
  get selectedFolder() {
    let folders = this.folderList
      .filter((item: any) => this.customFilters?.parent_folder?.includes(item.guid) && item.folderSelected)
      .map((item: any) => item.title);
    return folders;
  }
  get selectedType() {
    let types = this.document_types
      .filter((item: any) => this.customFilters?.document_types?.includes(item.slug) && item.typeSelected)
      .map((item: any) => item.value);

    return types;
  }
  get selectedRecurrence() {
    let recurrences = this.recurrenceSchedules
      .filter((item: any) => this.customFilters?.recurrence?.includes(item.value) && item.recurrenceSelected)
      .map((item: any) => item.display_value);
    return recurrences;
  }
  getFilteredKeys(): string[] {
    return Object.keys(this.customFilters).filter(
      key => Array.isArray(this.customFilters[key]) && this.customFilters[key].length > 0
    );
  }
  // old code

  ngOnInit() {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe((guid: string) => {
      this.OrganisationID = guid;
      this.getScheduleTypes();
      this.getPeopleList();
      this.getFolderList();
      this.getDocumentTypes();
      this.getAttentionList();
      this.getRole();
      this.getStatusTypes();
    });
    const enteredSearchValue: string = '';
    const action: string = this.route.snapshot.queryParamMap.get('action');
    const DocGuid: string = this.route.snapshot.queryParamMap.get('doc_guid');
    if (DocGuid) {
      this.getDocumentDetail(action, DocGuid);
      this.selectedDocGuid = DocGuid;
    }
  }

  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
        this.show_add_new_button = true;
      }
    }
  }

  updateStatus(y: any) {
    this.update_status = y.target.value;
    if (this.statusFilter == undefined) {
      this.update_status = '';
    }
  }

  searchDocumentByTitle(search_title: string) {
    this.loading = true;
    this.search_title = search_title;
    for (let i = 0; i < this.AttentionList.length; i++) {
      if (this.AttentionList[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    if (this.statusFilter === undefined) {
      this.statusFilter = '';
    }
    this.getAttentionList();
  }

  getStatusTypes() {
    if (this.OrganisationID !== null) {
      this.status_loading = true;
      this.documentsService.getOrganisationStatus(this.roleFilter).subscribe((data: any) => {
        this.documentStatus = data;
        for (let i = 0; i < this.documentStatus.length; i++) {
          if (this.documentStatus[i].value == 'All') {
            this.documentStatus[i]['isSelected'] = true;
          } else {
            this.documentStatus[i]['isSelected'] = false;
          }
        }
        this.status_loading = false;
      });
    }
  }
  getOverallDocumentTabCount() {
    this.documentsService.getOverallDocumentTabCount(this.OrganisationID).subscribe((data: any) => {
      this.documentTabStatus = data;
      this.tabsData.forEach((tab: any) => {
        if (this.documentTabStatus.hasOwnProperty(tab.countCode)) {
          tab.total = this.documentTabStatus[tab.countCode];
        }
      });
    });
  }
  getOverallDocumentStatusCount() {
    this.documentsService
      .getOverallDocumentStatusCount(this.OrganisationID, this.customFilterString)
      .subscribe((data: any) => {
        this.documentOverallStatus = data;
        for (const key of Object.keys(this.statusCountStruct)) {
          this.statusCount[key.replace('_', ' ')] = this.documentOverallStatus[key] || this.statusCountStruct[key];
        }
        this.statusCount[''] = data.total;
      });
  }

  load_status_list() {
    this.getStatusTypes();
  }

  onStatusChange(status: any, value: any) {
    this.loading = true;
    for (let i = 0; i < this.documentStatus.length; i++) {
      if (this.documentStatus[i].value == value) {
        this.documentStatus[i]['isSelected'] = true;
      } else {
        this.documentStatus[i]['isSelected'] = false;
      }
    }
    this.statusFilter = status;
    this.search_text = this.search_title;
    for (let i = 0; i < this.AttentionList.length; i++) {
      if (this.AttentionList[i]['status'] != this.statusFilter) {
        this.page = 1;
      }
    }
    this.getAttentionList();
  }
  onPublishStatusChange(status: any, value: any) {
    this.resetFilterNoAPiCall();
    this.status = '';
    this.loading = true;
    for (let i = 0; i < this.publishedStatus.length; i++) {
      if (this.publishedStatus[i].value == value) {
        this.publishedStatus[i]['isSelected'] = true;
      } else {
        this.publishedStatus[i]['isSelected'] = false;
      }
    }
    if (status == 'yours') {
      this.peopleList.forEach((item: any) => {
        if (this.userservice._userDetails.user['org_user_guid'] == item['guid']) {
          item['authorSelected'] = true;
        } else {
          item['authorSelected'] = false;
        }
      });
    } else {
      this.peopleList.forEach((item: any) => {
        item['authorSelected'] = false;
      });
    }
    this.applyCustomFilters();
  }

  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-document']);
  }

  onNavigate(docGuid: string) {
    this.documentsService
      .getOrganizationDocumentEditURl(this.OrganisationID, docGuid, { isCalledFromListPage: true })
      .subscribe(
        data => {
          this.statusAndActions = data;
          this.OpenDocument1(data.document_store_identifier, docGuid, this.statusAndActions);
          for (let i = 0; i < this.AttentionList.length; i++) {
            if (docGuid == this.AttentionList[i]['guid']) {
              this.AttentionList[i]['allowed_actions_list'] = this.statusAndActions['allowed_actions_list'];
              this.AttentionList[i]['status'] = this.statusAndActions['status'];
              break;
            }
          }
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  OpenDocument1(url: string, DocGuid: string, statusAndActions: any) {
    window.open(url, '_blank');
  }

  OpenDocument(url: string) {
    window.open(url, '_blank');
  }

  private getDocumentDetail(action: string, DocGuid: string) {
    this.documentsService.OrganizationDocumentSearchAPI(this.OrganisationID, DocGuid).subscribe(
      data => {
        this.DocumentDetails = data.results[0];
        if (
          action == 'view_document' &&
          DocGuid == this.DocumentDetails['in_progress'] &&
          this.DocumentDetails['allowed_actions_list'].includes('View Document')
        ) {
          this.OpenDocument(this.DocumentDetails['document_store_identifier']);
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  getAttentionList(): void {
    setTimeout(() => {
      this.getOverallDocumentStatusCount();
      this.getOverallDocumentTabCount();
    }, 1000);
    this.loading = true;
    if (this.OrganisationID != null) {
      this.documentsService
        .getOrganizationDocumentsList(
          this.OrganisationID,
          this.search_text,
          this.page,
          this.pageSize,
          this.statusFilter,
          this.roleFilter,
          this.customFilterString,
          this.apiEndPoint
        )
        .subscribe(
          data => {
            this.show = true;
            this.spnning = false;
            this.loading = false;
            this.paginatedDataUrl = data;
            this.AttentionList = data.results;
            // Check if selectedDocGuid exists in AttentionList
            if (this.selectedDocGuid) {
              const titles = this.AttentionList.filter((item: any) => item.in_progress === this.selectedDocGuid)
                .map((item: any) => item.title)
                .toString();
              this.searchDocumentByTitle(titles);
              this.searchText = titles;
            }
            if (!this.firstLoad) {
              this.notificationCount['attention_notification_count'] = data['attention_notification_count']
                ? data['attention_notification_count']
                : this.documentTabStatus?.attention_notification_count;
              this.notificationCount['published_notification_count'] = data['published_notification_count']
                ? data['published_notification_count']
                : this.documentTabStatus?.published_notification_count;
            } else {
              this.notificationCount[
                'attention_notification_count'
              ] = this.documentTabStatus?.attention_notification_count;
              this.notificationCount[
                'published_notification_count'
              ] = this.documentTabStatus?.published_notification_count;
              this.firstLoad = false;
            }

            this.totalLength = data.count;
          },
          error => {
            this.error = error.error.message;
            this.loading = false;
            this.spnning = false;
            this.show = true;
            console.log('Error:', error.error.message);
          }
        );
    }
  }
  refresh() {
    this.show = false;
    this.spnning = true;
    this.page = 1;
    this.searchText = '';
    this.statusFilter = '';
    this.AttentionList = [];
    this.getAttentionList();
    this.getStatusTypes();
  }

  ApplySearchFilter(page: any, search_text: string, status_filter: string) {
    if (search_text == undefined) {
      search_text = '';
    }
    if (status_filter == undefined) {
      status_filter = '';
    }
    this.documentsService.getPaginatedDocumentsList(this.OrganisationID, page, search_text, status_filter).subscribe(
      data => {
        this.AttentionList = data.results;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  openEditorApproverModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string, type = '') {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: type == 'Manage Approvers' ? 'Manage Approvers' : 'Manage Editors',
        modalType: type == 'Manage Approvers' ? 'Manage Approvers' : 'Manage Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        DocCreatedBy: DocCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          if (type == 'Manage Approvers') {
            this.documentsService.status.subscribe(data => {
              this.status6 = data;
              if (!this.status6) return;
              for (let i = 0; i <= this.AttentionList.length; i++) {
                if (this.clickedGuid == this.AttentionList[i]['guid']) {
                  this.AttentionList[i]['allowed_actions_list'] = this.status6['allowed_actions_list'];
                  this.AttentionList[i]['status'] = this.status6['status'];
                  this.AttentionList[i]['due_date'] = this.status6['due_date'];
                  if (
                    this.statusFilter !== '' &&
                    this.AttentionList[i]['status']['value'].toLowerCase() !== this.statusFilter.toLowerCase()
                  ) {
                    this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
                  }
                  break;
                }
              }
              this.translate
                .get('Document_Invite_Approver.invite_approver_toaster_message.invite_approver')
                .subscribe(res => {
                  this.toasterService.showSuccess(res);
                });
            });
          }
          if (type == 'Manage Editors') {
            this.documentsService.status.subscribe(data => {
              this.status5 = data;
              if (!this.status5) return;
              for (let i = 0; i <= this.AttentionList.length; i++) {
                if (this.clickedGuid == this.AttentionList[i]['guid']) {
                  this.AttentionList[i]['allowed_actions_list'] = this.status5['allowed_actions_list'];
                  this.AttentionList[i]['status'] = this.status5['status'];
                  this.AttentionList[i]['due_date'] = this.status5['due_date'];
                  if (
                    this.statusFilter !== '' &&
                    this.AttentionList[i]['status']['value'].toLowerCase() !== this.statusFilter.toLowerCase()
                  ) {
                    this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
                  }
                  break;
                }
              }
              this.translate
                .get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor')
                .subscribe(res => {
                  this.toasterService.showSuccess(res);
                });
            });
          }
        }
      });
  }

  // Invite Editors for Request Change code
  InviteEditorsForChangeReqOpenModal(
    DocumentGuid: string,
    DocCreatedBy: string,
    docTitle: string,
    updatedPage: number
  ): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Invite Editors for Request Change',
        modalType: 'Invite Editors for Request Change',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        DocCreatedBy: DocCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.AttentionList.length; i++) {
            if (this.clickedGuid == this.AttentionList[i]['guid']) {
              this.AttentionList[i] = result.result;
              if (
                this.statusFilter !== '' &&
                this.AttentionList[i]['status']['value'].toLowerCase() !== this.statusFilter.toLowerCase()
              ) {
                this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
              }
              break;
            }
          }
          this.AttentionList = this.AttentionList.splice(0, 13 * (updatedPage - 1));
          this.page = updatedPage;
          this.getAttentionList();
          this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        }
      });
  }
  MarkEditDoneOpenModal(DocumentGuid: string, docTitle: string, updatedPage: number): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Mark Editing as Done',
        modalType: 'Marked Editing as Done',
        message: 'Are you sure you want to mark the document <b> ' + docTitle + ' </b> as editing done?',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.AttentionList.length; i++) {
            if (this.clickedGuid == this.AttentionList[i]['guid']) {
              this.AttentionList[i] = result.result;

              if (
                this.statusFilter !== '' &&
                this.AttentionList[i]['status']['value'].toLowerCase() !== this.statusFilter.toLowerCase()
              ) {
                this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
              }

              break;
            }
          }
          this.AttentionList = this.AttentionList.splice(0, 13 * (updatedPage - 1));
          this.page = updatedPage;
          this.getAttentionList();

          this.translate
            .get('Mark_Editing_Done.mark_edit_done_toaster_message.mark_edit_done_submitted')
            .subscribe(res => {
              this.toasterService.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // move to In Edit Code
  MovetoInEditOpenModal(DocumentGuid: string, DocName: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Move to In Edit',
        message: 'Are you sure you want to move ' + DocName + ' to In Edit?',
        modalType: 'Move to In Edit',
        DocumentGuid: DocumentGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.ApplySearchFilter(this.page, this.search_text, this.statusFilter);
          this.translate
            .get('Move_to_InEdit.move_to_inedit_toaster_message.move_to_inedit_submitted')
            .subscribe(res => {
              this.toasterService.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Approve Changes Code
  ApproveChangesOpenModal(DocumentGuid: string, docTitle: string, updatedPage: number, action: string = ''): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: action == 'Approve Document' ? 'Approve Changes' : 'Continue Current Version',
        modalType: action == 'Approve Document' ? 'Approve Changes' : 'Continue Current Version',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.AttentionList.length; i++) {
            if (this.clickedGuid == this.AttentionList[i]['guid']) {
              this.AttentionList[i] = result.result;
              if (
                this.statusFilter !== '' &&
                this.AttentionList[i]['status']['value'].toLowerCase() !== this.statusFilter.toLowerCase()
              ) {
                this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
              }
              break;
            }
          }
          this.AttentionList = this.AttentionList.splice(0, 13 * (updatedPage - 1));
          this.page = updatedPage;
          this.getAttentionList();
          let msg =
            action == 'Approve Document'
              ? 'Approve_Changes.approve_changes_toaster_message.approve_changes_submitted'
              : 'Notification_Page.notification_toaster_message.continuing with current version';
          this.translate.get(msg).subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Approvers Code
  ViewApproversOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Approvers',
        modalType: 'View Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }

  // View Editors Code
  ViewEditorsOpenModal(DocumentGuid: string, docTitle: string, type: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Editors',
        modalType: 'View Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }

  // Publish Document Code
  openPublishDocumentForm(DocumentGuid: string, DocName: string, name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Publish Documents',
        modalType: 'Publish Documents',
        DocumentGuid: DocumentGuid,
        message: 'Are you sure you want to publish the document <b>' + DocName + '</b> ?',
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== this.clickedGuid);
          this.translate
            .get('Publish_Document.publish_document_toaster_message.publish_document_submitted')
            .subscribe(res => {
              this.toasterService.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  goTOEditDocument(DocGuid: any) {
    this.router.navigate(['/organisation/documents/edit-documents'], { queryParams: { DocGuid: DocGuid } });
  }
  goToDocumentdetailspage(DocGuid: any) {
    this.router.navigate(['organisation/documents/attention-required/' + DocGuid]);
  }
  OpenAddReadersModal(DocumentObj: any, DocumentVersion: any): void {
    const readerEmails = DocumentObj.users.map((x: any) => x.email);
    this.sidenavService
      .open(AddReadersComponent, {
        title: 'Add Readers',
        modalType: 'Add Readers',
        guid: DocumentObj.guid,
        readerEmails: readerEmails,
        DocumentVersion: DocumentVersion
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getAttentionList();
          //need to disscuss
          // if (this.isdefaultDocNameDisplayed) {
          //   this.startPeriodicFetch();
          // } else {
          //   this.getAttentionList();
          // }
        }
      });
  }
  getClickEvents(
    action: string,
    DocGuid: string,
    createdBy: string,
    docTitle: string,
    DocumentVersion: string,
    url: string,
    name: string,
    docInprogressGuid: string,
    index: number,
    attention: any
  ): void {
    let page: any = Math.floor(index / 13) + 1;
    this.clickedGuid = DocGuid;
    if (action == 'Edit Document') {
      this.onNavigate(DocGuid);
      this.AttentionList[index]['is_attention_required'] = false;
    } else if (action == 'Add Readers') {
      this.OpenAddReadersModal(attention, attention['document_version']);
    } else if (action == 'Manage Editors' || action == 'Manage Approvers') {
      this.openEditorApproverModal(DocGuid, createdBy, docTitle, action);
    } else if (action == 'View Editors') {
      this.ViewEditorsOpenModal(DocGuid, docTitle, action);
    } else if (action == 'Complete Edit') {
      this.MarkEditDoneOpenModal(DocGuid, docTitle, page);
    } else if (action == 'Request Change') {
      this.InviteEditorsForChangeReqOpenModal(DocGuid, createdBy, docTitle, page);
    } else if (action == 'View Approvers') {
      this.ViewApproversOpenModal(DocGuid, docTitle);
    } else if (action == 'Approve Document') {
      this.ApproveChangesOpenModal(DocGuid, docTitle, page, action);
    } else if (action == 'Publish Document') {
      this.openPublishDocumentForm(DocGuid, docTitle, name);
    } else if (action == 'View Document') {
      if (this.selectedDocumentTab == 'published-document') {
        this.viewPublishedDocument(this.AttentionList[index].document_version);
        this.AttentionList[index]['is_attention_required'] = false;
      } else {
        this.OpenDocument(url ? url : attention?.document?.document_store_identifier);
      }
    } else if (action == 'Edit Document Details') {
      // this.goTOEditDocument(docInprogressGuid)
      this.openCreateDocument(docInprogressGuid);
    } else if (action == 'View Document Details') {
      this.goToDocumentdetailspage(docInprogressGuid ? docInprogressGuid : this.AttentionList[index].document_version);
    } else if (action == 'Archive Document') {
      this.archiveDocument(docInprogressGuid, DocGuid);
    } else if (action == 'Create New Version') {
      this.NewVersionOpenModal(DocGuid, DocumentVersion, docTitle);
    } else if (action == 'Continue Current Version') {
      this.ApproveChangesOpenModal(DocGuid, docTitle, page, action);
    }
  }
  viewPublishedDocument(guid: string) {
    this.documentsService.getPublishedDocumentLink(this.OrganisationID, guid).subscribe(
      res => {
        this.OpenDocument(res.doc_store_identifier);
      },
      error => {
        this.toasterService.showError(error.message);
      }
    );
  } // for next deployment
  // else if (action == 'Add Readers') {
  //   this.OpenAddReadersModal(attention, attention['document_version']);
  // }
  archiveDocument(docInprogressGuid: string, guid: string) {
    if (this.selectedDocumentTab == 'published-document') {
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Archive Document',
          modalType: 'Archive Document',
          DocumentGuid: docInprogressGuid,
          message:
            'The action will archive all published versions of the document, and they will not be available for future training campaigns. Are you sure you want to archive the document?'
        })
        .pipe(take(1))
        .subscribe((result: any) => {
          if (result.isUpdate) {
            this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== guid);
          }
        });
    } else {
      this.documentsService.archiveDocument(this.OrganisationID, docInprogressGuid).subscribe(
        data => {
          this.AttentionList = this.AttentionList.filter((item: any) => item.guid !== guid);
          this.translate.get(data.message).subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        },
        error => {
          this.error = error.error.message;
          if (error.error.message) {
            this.toasterService.showError(this.error);
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.orgGuidSubscription.unsubscribe();
  }
  getInitials(name: string): string {
    const names = name?.split(' ');
    let initials = '';
    for (let i = 0; i < names?.length; i++) {
      if (names[i]) {
        initials += names[i][0].toUpperCase();
      }
    }
    return initials;
  }

  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  openNav(isImportDocClicked: boolean) {
    if (isImportDocClicked) {
      this.documentsService.isImportDocument.next(true);
    } else {
      this.documentsService.isImportDocument.next(false);
    }

    this.sidenavService
      .open(ImportDocumentAndTemplateComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          setTimeout(() => {
            this.getAttentionList();
          }, 2000);
        }
      });
  }
  unarchiveDocument(archiveDocument: ArchiveDocumentsResult) {
    this.loading = true;
    this.documentsService
      .unarchiveDocument(this.OrganisationID, archiveDocument.guid)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.getAttentionList();
        })
      )
      .subscribe(
        data => {
          this.translate.get(data.message).subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        },
        error => {
          this.error = error.error.message;
          this.toasterService.showError(this.error);
        }
      );
  }
  openCreateDocument(docInprogressGuid?: any) {
    this.documentsService.editDocumentGuid.next(docInprogressGuid);
    this.sidenavService
      .open(CreateDocumentComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          let msg = docInprogressGuid
            ? `Document ${result.result.title} edited successfully`
            : `Document ${result.result.title} created successfully`;
          this.toasterService.showSuccess(msg);
          this.getAttentionList();
        }
      });
  }
  openCreateTemplate() {
    this.sidenavService
      .open(CreateTemplateComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          let msg = `Template ${result.result.title} created successfully`;
          this.translate.get(msg).subscribe(res => {
            this.toasterService.showSuccess(res);
          });
          this.getAttentionList();
        }
      });
  }
  NewVersionOpenModal(DocumentGuid: string, DocumentVersion: string, docTitle: string): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Create New Version',
        modalType: 'Create New Version',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        DocumentVersion: DocumentVersion
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.status.subscribe(data => {
            this.status2 = data;
          });
          for (let i = 0; i <= this.AttentionList.length; i++) {
            if (this.clickedGuid == this.AttentionList[i]['guid']) {
              this.AttentionList[i]['allowed_actions_list'] = this.status2['allowed_actions_list'];
              break;
            }
          }
          this.translate;
          this.translate.get('Create_new_version.new_version_toaster_message.new_version').subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  toggleCheckbox(index: number, userRole: string) {
    if (userRole === 'editor') {
      this.peopleList[index].editorSelected = !this.peopleList[index].editorSelected;
    }
    if (userRole == 'author') {
      this.peopleList[index].authorSelected = !this.peopleList[index].authorSelected;
    }
    if (userRole == 'approver') {
      this.peopleList[index].approverSelected = !this.peopleList[index].approverSelected;
    }
  }
}
