<div class="training-container">
  <app-spinner *ngIf="loading"></app-spinner>
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
        <h2 class="text-cyan m-0" translate>Training_Page.Title</h2>
        <div class="col top-header-filters">
          <button mat-icon-button *ngIf="show" (click)="refresh()">
            <mat-icon class="refresh">refresh</mat-icon>
          </button>
          <i class="fa fa-spinner fa-spin refresh" style="margin-right: 11px;" *ngIf="spnning"></i>
          <div class="search-people position-relative d-flex">
            <div class="ng-multiselect-dropdown-margin-right" style="width: 250px;margin-right: 20px;">
              <ng-multiselect-dropdown
                id="select-document"
                class="multi-select-dropdown"
                [settings]="dropdownSetting"
                [placeholder]="'All Status'"
                [data]="trainingStatus"
                [(ngModel)]="selectedStatus"
                (onSelect)="onStatusChange($event)"
                (onDeSelect)="onStatusChange($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="input-group w-330">
              <div class="input-group w-330">
                <input
                  id="search"
                  [(ngModel)]="searchText"
                  class="form-control cb-input"
                  placeholder="{{ this.placeholderSearch }}"
                  name="search"
                  type="text"
                  (keyup)="updateSearchText($event)"
                  (keyDown.enter)="Search(searchText)"
                  #titleInput
                />
              </div>
              <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
            </div>
          </div>
          <button
            *ngIf="viewReoport"
            class="btn-save ml-4 w-100 mt-3 mb-3 float-right"
            translate
            [routerLink]="['/organisation/report']"
          >
            Report
          </button>
          <button
            class="btn-create ml-4 mt-3 mb-3 float-right  "
            translate
            [routerLink]="['/organisation/create-training-campaign']"
          >
            Create Training Campaign
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="key == 'organization_admin_role' || key == 'document_author'; else myDocuments">
      <mat-tab-group #tabRef (selectedTabChange)="changeTab(tabRef.selectedIndex)">
        <mat-tab style="font-weight: 600;" label="My Campaigns">
          <ng-container *ngTemplateOutlet="myCampaign"></ng-container>
        </mat-tab>
        <mat-tab label="My Documents">
          <button
            *ngIf="this.viewDocumentEnabled"
            (click)="this.documentLink = ''; this.viewDocumentEnabled = false"
            mat-stroked-button
            color="secondary"
            class="my-2 py-0 pr-2 pl-0 pb-1 mx-2"
          >
            <mat-icon style="color: grey;height: 24px;">
              keyboard_arrow_left
            </mat-icon>
            <span style="font-size: 14px;">Back</span>
          </button>

          <ng-container *ngTemplateOutlet="myDocuments"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #myCampaign>
      <app-spinner *ngIf="loadingCampaignSearch"></app-spinner>
      <ng-container *ngIf="!loadingCampaignSearch">
        <div class="row" *ngIf="TrainingList?.length > 0; else no_content">
          <div
            class="col p-0 search-results"
            infiniteScroll
            [scrollWindow]="false"
            (scrolled)="onScroll()"
            [infiniteScrollDistance]="modalScrollDistance"
            [infiniteScrollThrottle]="modalScrollThrottle"
            (scrolledUp)="onScrollUp()"
          >
            <div class="table_wrapper">
              <div class="ml-4">
                <button
                  mat-button
                  ngbTooltip="Select Columns"
                  tooltipClass="cb-tooltip"
                  [matMenuTriggerFor]="menu"
                  class="table-config-menu"
                >
                  <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                  <mat-menu #menu="matMenu">
                    <span class="table-config-menu-label">Select Columns</span>
                    <div class="table-config-menu-options">
                      <mat-checkbox
                        *ngFor="let cd of campaignsFilterColOptions; let i = index"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="showHideColumns[cd.key]"
                      >
                        {{ cd.label }}
                      </mat-checkbox>
                    </div>
                  </mat-menu>
                </button>
              </div>
              <table class="w-100p bg-white dm-table table table-responsive">
                <thead>
                  <tr style="height: 65px;">
                    <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                      Training_Page.Table_title
                    </th>

                    <th
                      *ngIf="showHideColumns.isShowRequestedBy"
                      scope="col"
                      class="font-weight-bold text-black-5 text-center"
                      translate
                    >
                      Training_Page.Table_requested
                    </th>
                    <th
                      *ngIf="showHideColumns.isshowStartDate"
                      scope="col"
                      class="font-weight-bold text-black-3 text-center"
                      translate
                    >
                      Training_Page.Start
                    </th>
                    <th scope="col" class="font-weight-bold text-black-3  campaign-status-end-date" translate>
                      Training_Page.End
                    </th>
                    <th
                      scope="col"
                      class="font-weight-bold text-black-3 campaign-status-end-date text-center"
                      translate
                    >
                      Training_Page.Table_status
                    </th>
                    <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                      Training_Page.Actions
                    </th>
                    <th scope="col" class="font-weight-bold text-black-3 text-center" translate></th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="text-center" *ngFor="let training of TrainingList; let rowIndex = index">
                    <td class="css-b8r46">
                      <abbr title="{{ training['title'] }}">
                        <h4 class="dm-text-3 text-black-4 mt-3">{{ training['title'] }}</h4>
                      </abbr>
                    </td>

                    <td *ngIf="showHideColumns.isShowRequestedBy">
                      <h4 class="text-black-5 dm-text-2 mt-3">
                        <img
                          src="../../../../assets/images/documents/author.png"
                          class="dm-user-icon"
                          *ngIf="training['requested_by']"
                        />
                        {{ training['created_by'] ? training['created_by']['name'] : '-' }}
                      </h4>
                    </td>
                    <td *ngIf="showHideColumns.isshowStartDate">
                      <h4 class="text-black-3 dm-text-2 mt-3">
                        {{ training['start_date'] ? training['start_date'] : '-' }}
                      </h4>
                    </td>
                    <td>
                      <h4 class="text-black-3 dm-text-2 mt-3">
                        {{ training['end_date'] ? training['end_date'] : '-' }}
                      </h4>
                    </td>
                    <td>
                      <h4 class="text-black-3 dm-text-3 mt-3">
                        <mat-icon
                          ngbTooltip="Overdue"
                          *ngIf="training['tag'] == 'overdue'"
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                          [ngStyle]="{ color: 'red' }"
                          class="overdue-icon mat-icon-alignment"
                          >access_time</mat-icon
                        >
                        <mat-icon
                          ngbTooltip="Completed"
                          *ngIf="training['tag'] == 'completed_on_time'"
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                          [ngStyle]="{ color: 'green' }"
                          class="overdue-icon mat-icon-alignment"
                          >access_time</mat-icon
                        >
                        <mat-icon
                          class="overdue-icon mat-icon-alignment"
                          *ngIf="training['tag'] == 'in_progress'"
                          ngbTooltip="In Progress"
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                          [ngStyle]="{ color: 'blue' }"
                          >access_time</mat-icon
                        >{{ training['status_description'] }}
                      </h4>
                    </td>
                    <td>
                      <mat-icon class="mt-2" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                      <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                        <ng-container *ngFor="let action of training['actions']">
                          <button mat-menu-item (click)="getOnClickEvents(action, training)">
                            {{ action }}
                          </button>
                        </ng-container>
                      </mat-menu>
                    </td>
                    <td>
                      <h4 class="text-black-3 dm-text-3 mt-3"></h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #myDocuments>
      <app-spinner *ngIf="loadingdocumentSearch"></app-spinner>
      <ng-container *ngIf="!loadingdocumentSearch">
        <ng-template #document>
          <div>
            <iframe
              [src]="documentLink"
              width="100%"
              height="800"
              frameborder="0"
              allowfullscreen
              webkitallowfullscreen
              msallowfullscreen
              credentialless
            ></iframe>
          </div>
        </ng-template>
        <ng-container *ngIf="!viewDocumentEnabled; else document">
          <div class="row" *ngIf="TrackingList?.length > 0; else no_content">
            <div
              class="col p-0 search-results"
              infiniteScroll
              [scrollWindow]="false"
              (scrolled)="onScroll()"
              [infiniteScrollDistance]="modalScrollDistance"
              [infiniteScrollThrottle]="modalScrollThrottle"
              (scrolledUp)="onScrollUp()"
            >
              <div class="table_wrapper">
                <div class="ml-4">
                  <button
                    mat-button
                    ngbTooltip="Select Columns"
                    tooltipClass="cb-tooltip"
                    [matMenuTriggerFor]="menu"
                    class="table-config-menu"
                  >
                    <mat-icon aria-hidden="false" [matTooltipPosition]="'right'">filter_list</mat-icon>
                    <mat-menu #menu="matMenu">
                      <span class="table-config-menu-label">Select Columns</span>
                      <div class="table-config-menu-options">
                        <mat-checkbox
                          *ngFor="let cd of docFilterColOptions; let i = index"
                          (click)="$event.stopPropagation()"
                          [(ngModel)]="showHideColumns[cd.key]"
                        >
                          {{ cd.label }}
                        </mat-checkbox>
                      </div>
                    </mat-menu>
                  </button>
                </div>
                <app-spinner *ngIf="loadingForDocuments"></app-spinner>
                <table class="w-100p bg-white dm-table table table-responsive">
                  <thead>
                    <tr style="height: 65px;">
                      <th
                        *ngIf="showHideColumns.isShowCampaign"
                        scope="col"
                        class="font-weight-bold text-black-33 text-center"
                        translate
                      >
                        Training_Page.Campaign
                      </th>
                      <th scope="col" class="font-weight-bold text-black-33 text-center" translate>
                        Training_Page.Document_Title
                      </th>
                      <th
                        *ngIf="showHideColumns.isShowType"
                        scope="col"
                        class="font-weight-bold text-black-33 text-center"
                        translate
                      >
                        Training_Page.Type
                      </th>
                      <th scope="col" class="font-weight-bold text-black-33 text-center" translate>
                        Training_Page.Version
                      </th>
                      <th scope="col" class="font-weight-bold text-black-55 text-center" translate>
                        Training_Page.Due_Date
                      </th>
                      <th scope="col" class="font-weight-bold text-black-33 text-center" translate>
                        Training_Page.Table_status
                      </th>
                      <th scope="col" class="font-weight-bold doc-action text-black-33 text-center" translate>
                        Training_Page.Actions
                      </th>
                      <th scope="col" class="font-weight-bold doc-action text-black-33 text-center" translate></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr class="text-center" *ngFor="let tracking of TrackingList; let rowIndex = index">
                      <td *ngIf="showHideColumns.isShowCampaign" class="css-b8r45">
                        <abbr title="{{ tracking['training_campaign']['title'] }}">
                          <h4 class="text-black-4 dm-text-3 mt-3">
                            {{ tracking['training_campaign']['title'] }}
                          </h4></abbr
                        >
                      </td>
                      <td
                        class="css-b8r45"
                        [ngStyle]="{ 'width.%': showHideColumns.isShowCampaign ? 22 : 30 }"
                        *ngIf="tracking['training_campaign_material'].type == 'cb_document'"
                      >
                        <abbr title="{{ tracking['training_campaign_material']['document']['title'] }}">
                          <h4 class="text-black-4 dm-text-3 mt-3">
                            {{ tracking['training_campaign_material']['document']['title'] }}
                          </h4>
                        </abbr>
                      </td>
                      <td class="css-b8r45" *ngIf="tracking['training_campaign_material'].type == 'external'">
                        <abbr title="{{ tracking['training_campaign_material']['external_link']['title'] }}">
                          <h4 class="text-black-4 dm-text-3 mt-3">
                            {{ tracking['training_campaign_material']['external_link']['title'] }}
                          </h4>
                        </abbr>
                      </td>
                      <td *ngIf="showHideColumns.isShowType">
                        <h4 class="text-black-33 dm-text-2 mt-3">
                          {{ tracking['training_campaign_material']['type_description'] }}
                        </h4>
                      </td>
                      <td>
                        <h4 class="text-black-33 dm-text-2 mt-3">
                          {{
                            tracking['training_campaign_material']?.document?.version
                              ? tracking['training_campaign_material']['document']['version']
                              : '-'
                          }}
                        </h4>
                      </td>

                      <td>
                        <h4 class="dm-text-3 text-black-33 mt-3">
                          {{ tracking['training_campaign'].due_date ? tracking['training_campaign'].due_date : '-' }}
                        </h4>
                      </td>
                      <td>
                        <h4 class="dm-text-3 text-black-33 mt-3">
                          <mat-icon
                            class="overdue-icon mat-icon-alignment"
                            *ngIf="tracking['tag'] == 'overdue'"
                            ngbTooltip="Overdue"
                            triggers="hover:blur"
                            tooltipClass="cb-tooltip"
                            placement="bottom"
                            [ngStyle]="{ color: 'red' }"
                            >access_time</mat-icon
                          >
                          <mat-icon
                            class="overdue-icon mat-icon-alignment"
                            *ngIf="tracking['tag'] == 'completed_on_time'"
                            ngbTooltip="Completed"
                            triggers="hover:blur"
                            tooltipClass="cb-tooltip"
                            placement="bottom"
                            [ngStyle]="{ color: 'green' }"
                            >access_time</mat-icon
                          >
                          <mat-icon
                            class="overdue-icon mat-icon-alignment"
                            *ngIf="tracking['tag'] == 'in_progress'"
                            ngbTooltip="In Progress"
                            triggers="hover:blur"
                            tooltipClass="cb-tooltip"
                            placement="bottom"
                            [ngStyle]="{ color: 'blue' }"
                            >access_time</mat-icon
                          >
                          {{ tracking['status_description'] }}
                        </h4>
                      </td>
                      <td>
                        <button
                          *ngIf="tracking['allowed_actions'].length === 1"
                          class="btn-save btn-width"
                          style="margin-top: 12px;"
                          [ngStyle]="{ 'background-color': '#4db7c6' }"
                          (click)="
                            getClickEvents(
                              tracking,
                              tracking['allowed_actions'][0],
                              tracking['guid'],
                              tracking['training_campaign'],
                              tracking['training_campaign_material'],
                              tracking['training_campaign_material']['document_store_identifier'],
                              tracking['training_campaign_material']['document'],
                              tracking['training_campaign_material']['type'],
                              tracking['assigned_date']
                            )
                          "
                        >
                          {{ tracking['allowed_actions'][0] }}
                        </button>
                        <mat-icon *ngIf="tracking['allowed_actions'].length > 1" class="mt-2" [matMenuTriggerFor]="menu"
                          >more_horiz</mat-icon
                        >
                        <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                          <ng-container *ngFor="let action of tracking['allowed_actions']">
                            <button
                              mat-menu-item
                              (click)="
                                getClickEvents(
                                  tracking,
                                  action,
                                  tracking['guid'],
                                  tracking['training_campaign'],
                                  tracking['training_campaign_material'],
                                  tracking['training_campaign_material']['document_store_identifier'],
                                  tracking['training_campaign_material']['document'],
                                  tracking['training_campaign_material']['type'],
                                  tracking['assigned_date']
                                )
                              "
                            >
                              {{ action }}
                              <mat-icon *ngIf="action == 'View Document'">launch</mat-icon>
                            </button>
                          </ng-container>
                        </mat-menu>
                      </td>
                      <td>
                        <h4 class="text-black-3 dm-text-3 mt-3"></h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #no_content>
    <div class="row ">
      <div class="col">
        <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
        <div class="text-empty-doc-list text-black-1" translate>Training_Page.No_documents</div>
        <a
          *ngIf="key"
          class="sub-text-empty-doc-list text-cyan-force"
          (click)="goToCreateDoc()"
          style="font-size: 18px;margin-left: 520px;"
          translate
          >Training_Page.Document</a
        >
      </div>
    </div>
  </ng-template>

  &nbsp;
  <div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
    <h2 class="font-weight-bold  ">
      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
    </h2>
  </div>
</div>
