import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-download-certificate',
  templateUrl: './download-certificate.component.html',
  styleUrls: ['./download-certificate.component.scss']
})
export class DownloadCertificateComponent implements OnInit {
  constructor(private orgService: OrganisationService, private settingsService: SettingsService) {}
  orgGuidSubscription: any;
  orgGuid: string | null;
  isSignatureLoading = false;
  loading = false;
  isDownloading = false;
  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  signature: any;
  userName: any;
  certificateData: any = {};
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  UserSignature: any = null;
  documentListItems: any = [];
  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.orgGuid = guid;
      this.getCertificateDetails();
    });
    this.getUserSignature();
  }
  generatePdf() {
    const data = document.getElementById('contentToConvert');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        const marginLeft = 2;

        const position = (pageHeight - imgHeight) / 2;

        pdf.addImage(contentDataURL, 'PNG', marginLeft, position, imgWidth - marginLeft, imgHeight);
        pdf.save('compliance-certificate.pdf');
      });
    }
    this.isDownloading = false;
  }
  loadGoogleFonts(user_name: string) {
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  getUserSignature() {
    this.isSignatureLoading = true;
    this.settingsService.getUserSignature(this.orgGuid).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  async addImageToCanvas() {
    const imgElement = document.getElementById('onlineImage') as HTMLImageElement;
    const imgUrl = imgElement.src;
    imgElement.src = await this.convertImageToBase64(imgUrl);
  }
  async convertImageToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }
  async generatePdfWithOnlineImages() {
    this.isDownloading = true;
    await this.addImageToCanvas();
    setTimeout(() => {
      this.generatePdf();
    }, 3000);
  }
  getCertificateDetails() {
    this.orgService.getCertificateDetails(this.orgGuid).subscribe((data: any) => {
      this.certificateData = data;
      const itemsPerColumn =
        this.certificateData.documents_list.length > 1
          ? Math.ceil(this.certificateData.documents_list.length / 2)
          : this.certificateData.documents_list.length;
      const firstColumn = document.getElementById('firstColumn');
      const secondColumn = document.getElementById('secondColumn');
      this.certificateData.documents_list.forEach((item: any, index: number) => {
        const listItem = document.createElement('li');
        listItem.textContent = item;
        listItem.classList.add('m-1');
        if (index < itemsPerColumn) {
          firstColumn.appendChild(listItem);
        } else {
          secondColumn.appendChild(listItem);
        }
      });
    });
  }
}
