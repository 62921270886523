<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="p-3 m-2 bg-white">
    <div class="row">
      <div class="col d-flex ">
        <div class="w-100p">
          <div class="row mt-2" *ngIf="backend_error">
            <div class="ml-3">
              <div class="text-danger" translate>* {{ backend_error }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between ">
            <div class="d-flex">
              <div class="module-link" (click)="redirectToDocumentManagement()">Policy Management</div>
              <mat-icon style="font-size: 20px;">keyboard_arrow_right</mat-icon>
              <span style="font-size: 13px;">{{ detailInfo.parent_folder }}</span>
            </div>
            <div class="d-flex align-items-center">
              <button
                *ngIf="editDocumentAccess"
                class="btn cb-btn-primary mx-2 rounded"
                (click)="
                  getClickEvents(
                    'Edit Document',
                    detailInfo.document_guid,
                    detailInfo.created_by.email,
                    title,
                    detailInfo.document_store_identifier,
                    detailInfo['version'],
                    detailInfo['guid'],
                    detailInfo
                  )
                "
              >
                Edit Document<mat-icon style="font-size: 16px; height: 16px;">open_in_new</mat-icon>
              </button>
              <button
                *ngIf="editDetailsAccess"
                class="btn cb-btn-primary mx-2 rounded"
                (click)="
                  getClickEvents(
                    'Edit Document Details',
                    detailInfo.document_guid,
                    detailInfo.created_by.email,
                    title,
                    detailInfo.document_store_identifier,
                    detailInfo['version'],
                    detailInfo['guid'],
                    detailInfo
                  )
                "
              >
                Edit Details
              </button>
              <div class="dropdown mx-2">
                <button
                  class="btn cb-btn-secondary dropdown-toggle rounded"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Version {{ detailInfo.version }}
                </button>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    *ngFor="let version of version; let i = index"
                    (click)="changeVersion(version.version)"
                    >{{ version['version'] }}</a
                  >
                </div>
              </div>
              <button
                *ngIf="detailInfo.allowed_actions_list.length"
                class="cb-icon-btn-primary action-btn"
                [matMenuTriggerFor]="menu"
              >
                <mat-icon style="font-size: 16px;height: 21px;" class="mt-1">more_vert</mat-icon>
              </button>

              <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                <ng-container *ngFor="let actions of filteredActions">
                  <button
                    mat-menu-item
                    (click)="
                      getClickEvents(
                        actions,
                        detailInfo.document_guid,
                        detailInfo['created_by']['email'],
                        detailInfo['title'],
                        detailInfo['document_store_identifier'],
                        detailInfo['version'],
                        detailInfo['guid'],
                        detailInfo
                      )
                    "
                  >
                    {{ actions }}
                    <mat-icon *ngIf="actions == 'Edit Document' || actions == 'View Document'">launch</mat-icon>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <div class="title-text">{{ detailInfo.title }}</div>
          <hr class="mx-0" />
          <div class="w-100p">
            <div class="description-text">DESCRIPTION</div>
            <div class="description-data mt-2">{{ detailInfo.description }}</div>
            <div class="mt-3 w-100p row justify-content-between">
              <div class="col-2">
                <div class="description-text">CREATED AT</div>
                <div>{{ detailInfo.created_timestamp | date: 'MMM d y' }}</div>
              </div>
              <div class="col-2">
                <div class="description-text">STATUS</div>
                <div>
                  {{ status }}
                </div>
              </div>
              <div class="col-2">
                <div class="description-text">RECURRENCE</div>
                <div class="text-uppercase" *ngFor="let item of detailInfo.renewal_schedule">
                  {{ item.renewal_schedule }}
                </div>
                <ng-container *ngIf="!detailInfo.renewal_schedule.length">
                  <div>-</div>
                </ng-container>
              </div>
              <div class="col-2">
                <div class="description-text">FOLDER</div>
                <div>{{ detailInfo.parent_folder }}</div>
              </div>
              <div class="col-2">
                <div class="description-text">AUTHOR</div>
                <div>{{ detailInfo['created_by']['name'] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div class="row mx-1 justify-content-between p-1">
    <div class="col-7 history-container bg-white ">
      <table class="table last-row-bordered">
        <tbody>
          <div class="history-title-container mx-1 py-4 px-3 ">
            <div class="description-text">HISTORY</div>
          </div>
          <tr class="table-row" *ngFor="let history of history_data; let indexOfelement = index">
            <td class="first">{{ history.history['action_timestamp'] | date }}</td>
            <td style="width: 120px;">{{ history.history['action_timestamp'] | date: 'shortTime' }}</td>

            <td>
              <ng-container *ngIf="history.history.action_type == 'requested_change'">
                <div class="">
                  <div class="history-title">
                    Requested Change
                  </div>
                  <div class="history-subtext">To {{ changeRequestAssignTo }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'new_draft'">
                <div class="">
                  <div class="history-title">
                    Document Created
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'invite_editor'">
                <div class="">
                  <div class="history-title">
                    Editors Added
                  </div>
                  <div class="history-subtext">
                    {{ history.assignedTo }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'edit_document'">
                <div class="">
                  <div class="history-title">
                    Started Editing
                  </div>
                  <div class="history-subtext">
                    {{ history.editDoc }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'revoke_editor'">
                <div class="">
                  <div class="history-title">
                    Editor Removed
                  </div>
                  <div class="history-subtext">
                    {{ history.editorRevoke }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'mark_editing_done'">
                <div class="">
                  <div class="history-title">
                    Completed Editing
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'all_completed_edit'">
                <div class="">
                  <div class="history-title">
                    Edit Completed
                  </div>
                  <div class="history-subtext">
                    Document Status Changed
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'invite_approver'">
                <div class="">
                  <div class="history-title">
                    Approver Added
                  </div>
                  <div class="history-subtext">
                    {{ history.assignedTo }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'revoke_approver'">
                <div class="">
                  <div class="history-title">
                    Removed Approved
                  </div>
                  <div class="history-subtext">
                    {{ history.approverRevoke }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'approve_document'">
                <div class="">
                  <div class="history-title">
                    Approved Document
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'all_approved'">
                <div class="r">
                  <div class="history-title">
                    All Approvers Approved
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'publish_document'">
                <div class="">
                  <div class="history-title">
                    Published Document
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="history.history.action_type == 'new_template'">
                <div class="">
                  <div class="history-title">
                    Created New Template
                  </div>
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="history.history.action_type == 'requested_change'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.changeRequestInitiatedBy">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.changeRequestInitiatedBy)">
                      {{ getInitials(history.changeRequestInitiatedBy) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.changeRequestInitiatedBy }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'new_draft'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.created_by">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.created_by)">
                      {{ getInitials(history.created_by) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.created_by }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'invite_editor'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.initiatedby">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.initiatedby)">
                      {{ getInitials(history.initiatedby) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.initiatedby }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'edit_document'">
                <div class="user-list" [matTooltip]="history.editDoc">
                  <ng-container *ngFor="let item of history.editDoc; let i = index">
                    <div class="initials-wrapper" [style.zIndex]="history.editDoc.length + i" *ngIf="i < 2">
                      <div class="initials-history" [style.backgroundColor]="getColor(item)">
                        {{ getInitials(item) }}
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="history.editDoc.length > 2" class="initials-wrapper" [style.zIndex]="10">
                    <div class="initials-history more">+{{ history.editDoc.length - 2 }}</div>
                  </div>
                  <span class="history-title" *ngIf="history.editDoc.length > 1"></span>
                  <div class="history-title ml-4">
                    {{ history.editDoc }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'revoke_editor'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.editorRevokedBy">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.editorRevokedBy)">
                      {{ getInitials(history.editorRevokedBy) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.editorRevokedBy }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'mark_editing_done'">
                <div class="user-list">
                  <div class="initials-wrapper">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.completed_by)">
                      {{ getInitials(history.completed_by) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.completed_by }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'all_completed_edit'">
                <div class="user-list" [matTooltip]="history.allcompleteedit">
                  <ng-container *ngFor="let item of history.allcompleteedit; let i = index">
                    <div class="initials-wrapper" [style.zIndex]="history.allcompleteedit.length + i" *ngIf="i < 2">
                      <div class="initials-history" [style.backgroundColor]="getColor(item)">
                        {{ getInitials(item) }}
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="history.allcompleteedit.length > 2" class="initials-wrapper" [style.zIndex]="10">
                    <div class="initials-history more">+{{ history.allcompleteedit.length - 2 }}</div>
                  </div>
                  <span class="history-title" *ngIf="history.allcompleteedit.length > 1"></span>
                  <div class="history-title ml-4">
                    All Editors
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'invite_approver'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.initiatedby">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.initiatedby)">
                      {{ getInitials(history.initiatedby) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.initiatedby }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'revoke_approver'">
                <div class="user-list">
                  <div class="initials-wrapper" [matTooltip]="history.approverRevokedBy">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.approverRevokedBy)">
                      {{ getInitials(history.approverRevokedBy) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.approverRevokedBy }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'approve_document'">
                <div class="user-list">
                  <div class="initials-wrapper">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.docApprover)">
                      {{ getInitials(history.docApprover) }}
                    </div>
                  </div>
                  <div class="history-title ml-4">
                    {{ history.docApprover }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'all_approved'">
                <div class="user-list" [matTooltip]="history.allApprover">
                  <ng-container *ngFor="let item of history.allApprover; let i = index">
                    <div class="initials-wrapper" [style.zIndex]="history.allApprover.length + i" *ngIf="i < 2">
                      <div class="initials-history" [style.backgroundColor]="getColor(item)">
                        {{ getInitials(item) }}
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="history.allApprover.length > 2" class="initials-wrapper" [style.zIndex]="10">
                    <div class="initials-history more">+{{ history.allApprover.length - 2 }}</div>
                  </div>
                  <span class="history-title" *ngIf="history.allApprover.length > 1"></span>
                  <div class="history-title ml-4">
                    All Approvers
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'publish_document'">
                <div class="user-list">
                  <div class="initials-wrapper">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.published_by)">
                      {{ getInitials(history.published_by) }}
                    </div>
                  </div>
                  <div>
                    <div class="history-title ml-4">
                      {{ history.published_by }}
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="history.history.action_type == 'new_template'">
                <div class="user-list">
                  <div class="initials-wrapper">
                    <div class="initials-history" [style.backgroundColor]="getColor(history.published_by)">
                      {{ getInitials(history.published_by) }}
                    </div>
                    <div class="history-title ml-4">
                      {{ history.published_by }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </td>
            <!-- Circles here -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-5 pr-0 w-100p">
      <div class="d-flex justify-content-between bg-white">
        <div class="accordion w-100p" id="faq">
          <div class="d-flex justify-content-between w-100p align-items-center" id="faqhead1">
            <div
              class="description-text mx-2 cursor-pointer"
              data-toggle="collapse"
              data-target="#faq1"
              aria-expanded="true"
              aria-controls="faq1"
            >
              EDITORS ({{ detailInfo.editors?.length }})
            </div>
            <div class="d-flex align-items-center">
              <button
                *ngIf="editorAccess && detailInfo.is_author"
                class="btn btn-sm cb-btn-secondary   mx-2 rounded"
                (click)="
                  getClickEvents(
                    'Manage Editors',
                    detailInfo.document_guid,
                    detailInfo.created_by.email,
                    title,
                    detailInfo.document_store_identifier,
                    detailInfo['version'],
                    detailInfo['guid'],
                    detailInfo
                  )
                "
              >
                Add Editor
              </button>
              <div class="btn-header-link mx-2" data-target="#faq1" aria-expanded="true" aria-controls="faq1"></div>
            </div>
          </div>
          <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
            <ng-container *ngIf="detailInfo.editors.length; else noData">
              <div class="p-3 content-container" *ngFor="let item of detailInfo.editors">
                <div class="d-flex align-items-center">
                  <div class="initials-wrapper mr-1">
                    <div class="initials" [style.backgroundColor]="getColor(item.user.name)">
                      {{ getInitials(item.user.name) }}
                    </div>
                  </div>
                  <div class="edi-app-name">
                    {{ item.user.name }}
                    <div class="edi-app-email">
                      {{ item.user.email }}
                    </div>
                  </div>
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Due Date : <span style="color: black !important;"> {{ item?.due_date | date: 'MM-dd-yyyy' }}</span>
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Status :
                  <span class="text-black">
                    <span style="color: black !important;">{{ item?.status }}</span></span
                  >
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Comments :
                  <div style="color: black !important;width: 430px;">{{ item?.start_message }}</div>
                </div>
                <div class="action-container d-flex w-100p justify-content-end">
                  <button
                    class="mx-2"
                    *ngIf="item.status.toLowerCase() == 'yet to start' && editorsActionAccess && detailInfo.is_author"
                    (click)="openRevokeEditorForm(detailInfo.document_guid, item.guid, item.user.name)"
                  >
                    <img src="/assets/images/core/delete.svg" />
                  </button>
                  <button
                    class="mx-2 cb-icon-btn-primary"
                    *ngIf="
                      (item.status.toLowerCase() == 'yet to start' || item.status.toLowerCase() == 'editing') &&
                      editorsActionAccess &&
                      detailInfo.is_author
                    "
                    (click)="
                      openChangeDueFrom(
                        detailInfo.document_guid,
                        item.guid,
                        item.user.email,
                        item.user.name,
                        item.start_message,
                        item.due_date,
                        'editor'
                      )
                    "
                  >
                    <img src="/assets/images/core/edit-editor.svg" />
                  </button>
                  <button
                    class="mx-2"
                    *ngIf="item.status.toLowerCase() == 'completed' && editorsActionAccess && detailInfo.is_author"
                    (click)="
                      openReassignEditorApproverForm(
                        detailInfo.document_guid,
                        item.org_user_guid,
                        item.user.email,
                        item.user.name,
                        item.due_date,
                        'editor'
                      )
                    "
                  >
                    <img src="/assets/images/core/redo.svg" />
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-template #noData>
              <div class="p-3 no-data-block">
                <div>
                  <img class="w-100p" src="assets/images/core/add-editor-app.svg" height="60" alt="" srcset="" />
                  <div class="editor-add-sub">
                    Add Editors to review and make changes to the policy document
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between bg-white">
        <div class="accordion w-100p" id="faq">
          <div class="d-flex justify-content-between w-100p align-items-center" id="faqhead1">
            <div
              class="description-text mx-2 cursor-pointer "
              data-toggle="collapse"
              data-target="#faq2"
              aria-expanded="true"
              aria-controls="faq2"
            >
              APPROVERS({{ detailInfo.approvers?.length }})
            </div>
            <div class="d-flex align-items-center">
              <button
                *ngIf="approverAccess && detailInfo.is_author"
                class="btn btn-sm cb-btn-secondary mx-2 rounded"
                (click)="
                  getClickEvents(
                    'Manage Approvers',
                    detailInfo.document_guid,
                    detailInfo.created_by.email,
                    title,
                    detailInfo.document_store_identifier,
                    detailInfo['version'],
                    detailInfo['guid'],
                    detailInfo
                  )
                "
              >
                Add Approver
              </button>
              <div class="btn-header-link mx-2" data-target="#faq2" aria-expanded="true" aria-controls="faq2"></div>
            </div>
          </div>
          <div id="faq2" class="collapse " aria-labelledby="faqhead1" data-parent="#faq">
            <ng-container *ngIf="detailInfo.approvers.length; else noDataApprover">
              <div class="p-3 content-container" *ngFor="let item of detailInfo.approvers">
                <div class="d-flex align-items-center">
                  <div class="initials-wrapper mr-1">
                    <div class="initials" [style.backgroundColor]="getColor(item.user.name)">
                      {{ getInitials(item.user.name) }}
                    </div>
                  </div>
                  <div class="edi-app-name">
                    {{ item.user.name }}
                    <div class="edi-app-email">
                      {{ item.user.email }}
                    </div>
                  </div>
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Due Date : <span style="color: black !important;"> {{ item?.due_date | date: 'MM-dd-yyyy' }}</span>
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Status :
                  <span class="text-black">
                    <span style="color: black !important;">{{ item?.status }}</span></span
                  >
                </div>
                <div class="description-text my-2" style="font-weight: 400;">
                  Comments :
                  <span class="text-black">
                    <span style="color: black !important;">{{ item?.start_message }}</span></span
                  >
                </div>
                <div class="action-container d-flex w-100p justify-content-end">
                  <button
                    class="mx-2"
                    *ngIf="item.status.toLowerCase() == 'yet to start' && approveraActionAccess && detailInfo.is_author"
                    (click)="removeApprover(detailInfo.document_guid, item.guid, item.user)"
                  >
                    <img src="/assets/images/core/delete.svg" />
                  </button>
                  <button
                    class="mx-2"
                    *ngIf="
                      (item.status.toLowerCase() == 'yet to start' || item.status.toLowerCase() == 'editing') &&
                      approveraActionAccess &&
                      detailInfo.is_author
                    "
                    (click)="
                      openChangeDueFrom(
                        detailInfo.document_guid,
                        item.guid,
                        item.user.email,
                        item.user.name,
                        item.start_message,
                        item.due_date,
                        'approver'
                      )
                    "
                  >
                    <img src="/assets/images/core/edit-editor.svg" />
                  </button>
                  <button
                    class="mx-2"
                    *ngIf="item.status.toLowerCase() == 'approved' && approveraActionAccess && detailInfo.is_author"
                    (click)="
                      openReassignEditorApproverForm(
                        detailInfo.document_guid,
                        item.user.org_user_guid,
                        item.user.email,
                        item.user.name,
                        item.due_date,
                        'approver'
                      )
                    "
                  >
                    <img src="/assets/images/core/redo.svg" />
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-template #noDataApprover>
              <div class="p-3 no-data-block">
                <div>
                  <img class="w-100p" src="assets/images/core/add-editor-app.svg" height="60" alt="" srcset="" />
                  <div class="editor-add-sub">
                    Add Approvers to review and make changes to the policy document
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
