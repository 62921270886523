import { SidenavService } from './../../../core/modal/sidenav.service';
import { Component, OnInit, Input, inject, ViewChildren, ElementRef, EventEmitter, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { OrganisationService } from '@app/core/organisation.service';
import * as _ from 'lodash';
import { DocumentsService } from '../documents.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { E, G } from '@angular/cdk/keycodes';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { GoogleDriveUploadService } from '../google-drive-upload.service';
import { CustomsidenavComponent } from '@app/shared/modal/customsidenav/customsidenav.component';

declare var require: any;
const moment = require('moment');

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.scss']
})
export class CreateDocumentComponent implements OnInit, AfterViewInit {
  @Input() dialogData: any = null;
  backendError: string;
  isloading = false;
  docTitle = stringInfo.Create_Document.Doc_Title;
  time = stringInfo.Create_Document.Time;
  version = stringInfo.Create_Document.Version_Placeholder;
  FolderList: object[];
  SubFolderList: object[];
  document_types: any = [];
  OrganisationID: string;
  DocStoreguid: string;
  addDocumentForm: FormGroup;
  selectFolderForm: FormGroup;
  submitted = false;
  type: string;
  allowed_duration_data: {};
  allowed_duration_selected: string;
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  form_error: any;
  backend_error: any;
  document_type: any;
  error_message: any;
  folder_name: any;
  selectedFolderDetails: any;
  UserSignature: any = null;
  disabledBtn = true;
  signature_error: string = '';
  oldFolderName: any;
  editDocumentGuid: string = '';
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };
  error: any;
  selected_option: any;
  subfolder: any[];
  checkedItem: string;
  parent_folder: any;
  template_name: any = '';
  template_guid: any;
  documentDetails: any;
  folderName: any;
  renewalRecurring: any;
  Docguid: any;
  time_12: string = '';
  isLoading: boolean = false;
  loading: boolean = false;
  originalTitle: string = null;
  createDocStorageId: string = 'create_doc_folder_data';
  createDoctemplateStorageId: string = 'create_doc_template_data';
  fileName: string | null = null;
  subFolderData: any;

  DocCreate_Options = [
    { id: 'upload_document', name: 'Upload a document' },
    { id: 'select_template', name: 'Choose from templates' },
    { id: 'select_document_type', name: 'Create a blank document' }
  ];
  document_guid: any;

  upload_template_name: any = '';
  checkFileFormat: any;
  guidSubscription: any;

  DocOptionsChange(option: any) {
    this.selected_option = option.target.value;
    this.disabledBtn = true;
    if (option.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    if (this.selected_option == 'select_template') {
      if (this.template_guid) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    } else if (this.selected_option == 'select_document_type') {
      if (
        this.document_type == 'document' ||
        this.document_type == 'spreadsheet' ||
        this.document_type == 'presentation'
      ) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    }
  }

  openSelectTemplateForm() {
    this.SelectTemplateOpenModal();
  }

  SelectTemplateOpenModal(): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Select Template',
        modalType: 'Select Template',
        template_guid: this.template_guid,
        storage_id: this.createDoctemplateStorageId,
        type: 'general'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };

  constructor(
    private backendErrorService: BackendErrorService,
    private orgAdminService: OrganisationAdminService,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService,
    private http: HttpClient,
    private googleDriveService: GoogleDriveUploadService,
    private sidenavService: SidenavService
  ) {}

  static convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('mySidenavDoc').classList.toggle('open');
    }, 100);
    localStorage.removeItem('submittedTemplateData');
    this.oldFolderName = this.folder_name;
    this.selected_option = 'select_doc_opt';
    localStorage.removeItem(this.createDocStorageId);
    localStorage.removeItem(this.createDoctemplateStorageId);

    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });

    this.addDocumentForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      document_type: [''],
      document_option: ['', [Validators.required()]],
      version: ['', [Validators.required(), Validators.restrictZeroNumber()]],
      renewal_recurring: [''],
      file: ['']
    });

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.FolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
      this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
        this.document_types = data;
      });
    });

    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );

    if (this.dialogData) {
      this.data = _.cloneDeep(this.dialogData.event.schedule);
      this.getAllowedDuration(this.dialogData.event.schedule.renewal_schedule, this.dialogData.event.allowed_duration);
      if (_.indexOf(this.dialogData.event.schedule.day, ' ') > 0) {
        const split_day = this.data.day.split(' ');
        this.data.day = split_day[0];
        this.data.day_of_week = split_day[1];
      }
      if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
        const year_data = this.data.year.split('/');
        if (+year_data[0] % 2 === 1) {
          this.data.year = '1/2';
        } else {
          this.data.year = '2/2';
        }
      }
    } else {
      this.getAllowedDuration();
      this.data = {
        renewal_schedule: 'SEMI-ANNUALLY',
        time: '09:00',
        day_of_week: 'mon',
        week: null,
        day: '1st',
        month: '1/6',
        year: '*'
      };
    }
    this.documentsService.editDocumentGuid.subscribe((name: any) => {
      this.editDocumentGuid = name;
    });
    this.documentsService.folderName.subscribe((name: any) => {
      this.subFolderData = name;
      this.folder_name = this.subFolderData?.title;
      this.parent_folder = this.subFolderData?.guid;
    });
    if (this.editDocumentGuid) {
      this.loading = true;
      this.getDocumentDetailPageInfo(this.OrganisationID, this.editDocumentGuid);
    }
  }

  getDocumentDetailPageInfo(OrganisationID: any, guid: any): void {
    this.documentsService.getDocumentDetailPage(OrganisationID, guid).subscribe(
      data => {
        this.documentDetails = data;
        this.loading = false;
        this.folder_name = this.documentDetails['parent_folder'];
        this.renewalRecurring = this.documentDetails['renewal_schedule'].length;
        if (this.documentDetails['renewal_schedule'].length > 0) {
          this.data = _.cloneDeep(this.documentDetails['renewal_schedule'][0]);
          this.getAllowedDuration(
            this.documentDetails['renewal_schedule'][0]['renewal_schedule'],
            this.documentDetails['renewal_schedule'][0]['allowed_duration']
          );
          if (_.indexOf(this.documentDetails['renewal_schedule'][0]['day'], ' ') > 0) {
            const split_day = this.data.day.split(' ');
            this.data.day = split_day[0];
            this.data.day_of_week = split_day[1];
            this.data.time = this.convertTo24HourFormat(this.documentDetails['renewal_schedule'][0]['time']);
          }
          if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
            const year_data = this.data.year.split('/');
            if (+year_data[0] % 2 === 1) {
              this.data.year = '1/2';
            } else {
              this.data.year = '2/2';
            }
          }
        } else {
          this.getAllowedDuration();
          this.data = {
            renewal_schedule: 'SEMI-ANNUALLY',
            time: '09:00',
            day_of_week: 'mon',
            week: null,
            day: '1st',
            month: '1/6',
            year: '*'
          };
        }
        this.Docguid = this.documentDetails['document_guid'];
        if (this.renewalRecurring <= 0) {
          this.documentDetails['renewal_schedule'] = null;
        }
        this.originalTitle = this.documentDetails.title;
        this.addDocumentForm.patchValue({
          title: this.documentDetails.title,
          description: this.documentDetails.description,
          document_type: this.documentDetails.document_store_identifier,
          document_option: this.documentDetails.document_guid,
          version: this.documentDetails.version,
          renewal_recurring: this.documentDetails.renewal_schedule
        });
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }
  convertTo24HourFormat(time12h: string): string {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = modifier.toLowerCase() === 'am' ? '00' : '12';
    } else if (modifier.toLowerCase() === 'pm') {
      hours = String(parseInt(hours, 10) + 12);
    }

    return `${hours.padStart(2, '0')}:${minutes}:00`;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    localStorage.removeItem(this.createDocStorageId);
    localStorage.removeItem(this.createDoctemplateStorageId);
  }

  onReset() {
    this.submitted = false;
    this.addDocumentForm.reset();
    this.folder_name = '';
    this.template_name = '';
    this.selected_option = '';
    localStorage.removeItem(this.createDocStorageId);
    this.backend_error = '';
    localStorage.removeItem(this.createDoctemplateStorageId);
  }

  dataRefresh(event: any) {
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.loading = true;
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      this.loading = false;
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }

  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  timeSet(time: any) {
    this.data.time = time;
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }
  selectDocType(evt: any) {
    this.document_type = evt.target.value;
    if (evt.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    this.disabledBtn = false;
  }
  file: any;
  size: any;
  fileEvent(input: any) {
    this.disabledBtn = false;
    this.file = input.target.files[0];
    if (this.file) {
      this.fileName = input.target.files[0].name;
    } else {
      this.fileName = null;
    }
    this.document_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);

    this.size = this.file.size;
    if (
      this.document_type == 'docx' ||
      this.document_type == 'doc' ||
      this.document_type == 'pptx' ||
      this.document_type == 'xlsx' ||
      this.document_type == 'pdf'
    ) {
      this.upload_error_message = '';
    } else {
      this.upload_error_message = 'Please select .docx,.ppt,.xlsx,.pdf format only.';
    }
  }

  ngAfterViewInit() {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      let storedFolderObj = localStorage.getItem(this.createDocStorageId);
      if (storedFolderObj) {
        let storedFolderData = JSON.parse(storedFolderObj);
        this.selectedFolderDetails = shareSelectedFolder;
        this.folder_name = storedFolderData.folderPath;
        this.parent_folder = storedFolderData.selectedFolderGuid;
      }
    });

    this.documentsService.getTemplateName().subscribe((item: any) => {
      this.template_name = this.documentsService.getTemplate();
      if (this.template_name) {
        this.disabledBtn = false;
      }
    });
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_guid = this.documentsService.getTemplateGuid()));
  }
  ngOnChanges(): void {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      let storedFolderObj = localStorage.getItem(this.createDocStorageId);
    });
  }

  template_error_message = 'Please select at least one template.';
  upload_error_message = '* Please upload document.';

  checkDocumentType(template_name: any) {
    let template_type = '';
    let type = template_name.substr(template_name.lastIndexOf('.') + 1);
    if (type == 'pptx' || type == 'ppt') {
      template_type = 'presentation';
    } else if (type == 'docx' || type == 'doc') {
      template_type = 'document';
    } else if (type == 'xlsx' || type == 'xls') {
      template_type = 'spreadsheet';
    } else if (type == 'pdf') {
      template_type = 'pdf';
    }
    return template_type;
  }
  convertTo12HourFormat(time24: string): string {
    const [hour, minute] = time24.split(':').map(Number);
    const meridian = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${meridian}`;
  }
  onChangeTime(event: any): void {
    const time24 = event.target.value;
    const time12 = this.convertTo12HourFormat(time24);
    this.time_12 = time12;
    this.data.time = time24;
  }

  submit() {
    this.isLoading = true;
    if (this.folder_name != this.oldFolderName) {
      this.backend_error = '';
    }
    this.submitted = true;
    if (this.addDocumentForm.valid && this.folder_name) {
      const document_data = Object.assign({}, this.addDocumentForm.value);

      document_data['org_document_store'] = this.DocStoreguid;

      if (document_data.renewal_recurring) {
        document_data['renewal_schedule'] = this.getPreparedData();
        document_data['renewal_schedule']['day_of_week'] = this.data['day_of_week'];
        document_data['renewal_schedule']['week'] = this.data['week'];
        document_data['renewal_schedule']['time'] = this.time_12
          ? this.time_12
          : this.documentDetails?.renewal_schedule?.[0]['time'];
      } else {
        document_data['renewal_schedule'] = null;
        document_data['day_of_week'] = null;
      }

      if (this.folder_name !== '') {
        let data = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      } else {
        let data1 = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      }
      this.error_message = '';
      if (this.selected_option == 'select_template' && !this.template_name) {
        return this.template_error_message;
      } else if (this.selected_option == 'select_template' && this.template_name) {
        document_data['template'] = this.template_guid;
        this.checkDocumentType(this.template_name);
        document_data['document_type'] = this.checkDocumentType(this.template_name);
        delete document_data.file;
        this.submitCreateDocumentData(document_data);
      } else if (this.selected_option == 'select_document_type') {
        delete document_data.file;
        this.submitCreateDocumentData(document_data);
      } else if (this.selected_option == 'upload_document') {
        this.createDocumentUploadPayload(document_data);
      } else if (this.editDocumentGuid) {
        this.submitEditDocumentData(document_data);
      }
    } else {
      this.error_message = 'Please select at least one checkbox.';
      return;
    }
  }

  submitEditDocumentData(document_data: any): void {
    if (this.addDocumentForm.value.title === this.originalTitle) {
      document_data.title = null;
    }
    document_data['document_version_guid'] = this.documentDetails.guid;
    this.loading = true;
    delete document_data['document_option'];
    this.documentsService
      .submitOrganisationEditDocumentData(this.OrganisationID, document_data, this.Docguid)
      .subscribe(
        data => {
          this.isloading = false;
          this.isLoading = false;
          this.loading = false;
          this.error = {
            title: '',
            version: '',
            folder: ''
          };
          this.submitted = false;
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.sidenavService.close(false);

          this.backend_error = '';
        },
        (error: any) => {
          this.isloading = false;
          this.submitted = false;
          this.loading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
  createDocumentUploadPayload(document_data: any): void {
    this.loading = true;
    const data = {
      title: document_data.title,
      filename: this.file.name,
      size: this.size,
      folder_guid: this.parent_folder
    };
    this.checkFileFormat = data.filename.substr(this.file.name.lastIndexOf('.') + 1);
    if (
      this.checkFileFormat == 'docx' ||
      this.checkFileFormat == 'doc' ||
      this.checkFileFormat == 'pptx' ||
      this.checkFileFormat == 'xlsx' ||
      this.document_type == 'pdf'
    ) {
      this.upload_error_message = '';
      this.documentsService.uploadDocument(this.OrganisationID, data).subscribe(
        async (data: any) => {
          if (data['store_type'] == 'GOOGLE_DRIVE') {
            document_data['document_type'] = data.type;
            try {
              const { fileId, fileType } = await this.googleDriveService.uploadFileAndGetInfo(
                this.file,
                data['temp_token'],
                data['folder_id'],
                document_data.title
              );
              document_data['file'] = fileId;
              this.submitCreateDocumentData(document_data);
              // Handle successful upload
            } catch (error) {
              this.backend_error = error;
              console.error('Error uploading file:', error);
            }
          } else {
            let attributes = {
              name: data.title,
              parent: { id: data.restricted_to }
            };
            document_data['document_type'] = data.type;
            this.documentsService.uploadDocumentToBox(data.url, this.file, data.access_token, attributes).subscribe(
              (box: any) => {
                document_data['file'] = box.entries[0].id;
                this.submitCreateDocumentData(document_data);
              },
              (error: any) => {
                this.loading = false;
                this.submitted = false;
                this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                this.backendErrorService.backendError$.subscribe(error => {
                  this.backend_error = error;
                });
              }
            );
          }
        },
        (error: any) => {
          this.isloading = false;
          this.submitted = false;
          this.loading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    }
  }

  submitCreateDocumentData(document_data: any): void {
    this.isloading = true;
    this.loading = true;
    delete document_data['folder'];
    delete document_data['document_option'];
    this.documentsService.submitOrganisationAddDocumentData(this.OrganisationID, document_data).subscribe(
      data => {
        this.isloading = false;
        this.loading = false;
        this.error = {
          title: '',
          version: '',
          folder: ''
        };
        // this.submitted = false;
        // this.modalService
        //   .custom(CustomDialogComponent, {
        //     title: 'Document created',
        //     modalType: 'Document created',
        //     DocumentGuid: data.guid,
        //     DocumentTitle: data.title
        //   })
        //   .pipe(take(1)) // take() manages unsubscription for us
        //   .subscribe((result: any) => {
        //     if (result) {
        //     } else {
        //     }
        //   });
        this.submitted = false;
        this.addDocumentForm.reset();
        this.selectFolderForm.reset();
        this.folder_name = '';
        this.template_name = '';
        this.selected_option = '';
        this.backend_error = '';
        this.sidenavService.updateData({ isUpdate: true, result: data });
        this.sidenavService.close(false);
        localStorage.removeItem(this.createDocStorageId);
        localStorage.removeItem(this.createDoctemplateStorageId);
      },
      (error: any) => {
        this.isloading = false;
        this.submitted = false;
        this.loading = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          // this.sidenavService.updateData(this.backend_error, true);
        });
        this.toaster.showError(this.backend_error);
      }
    );
  }
  onFolderChange(evt: any) {}

  closeNav() {
    this.backend_error = '';
    this.addDocumentForm.reset();
    this.sidenavService.close(true);
  }
  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.documentsService.setSelectedFolderData(this.selectedFolderDetails);
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Select a folder',
        storage_id: this.createDocStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
}
