import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { OrgAdminShell } from '@app/shells/orgAdminshell/orgAdminshell.service';
import { EventsComponent as EC1, EventsComponent } from '@app/Organisation-admin/Events/events.component';
import { EventDetailsComponent } from './Events/event-details/event-details.component';
import { OrgDashboardComponent } from '@app/Organisation-admin/orgDashboard/orgDashboard.component';
import { OrgDashboardHistoryComponent } from '@app/Organisation-admin/orgDashboardHistory/orgDashboardHistory.component';
import { DocumentsComponent } from './documents/documents.component';
import { AttentionRequiredComponent } from './documents/attention-required/attention-required.component';
import { CreateDocumentComponent } from './documents/create-document/create-document.component';
import { TrainingComponent } from './documents/training/training.component';
import { VendorManagementComponent } from './documents/vendor-management/vendor-management.component';
import { ComplianceManagementComponent } from './documents/compliance-management/compliance-management.component';
import { RiskManagementComponent } from './documents/risk-management/risk-management.component';
import { ItManagementComponent } from './documents/it-management/it-management.component';
import { TemplatesComponent } from './documents/templates/templates.component';
import { NotificationsComponent } from './documents/notifications/notifications.component';
import { RetiredDocsComponent } from './documents/retired-docs/retired-docs.component';
import { FormsComponent } from './documents/forms/forms.component';
import { CreateTemplateComponent } from './documents/create-template/create-template.component';
import { CreateFormComponent } from './documents/create-form/create-form.component';
import { CreateFolderComponent } from './documents/create-folder/create-folder.component';
import { CreateSubFolderComponent } from './documents/create-sub-folder/create-sub-folder.component';
import { PublishedDocumentComponent } from './documents/published-document/published-document.component';
import { DocumentDetailPageComponent } from './documents/document-detail-page/document-detail-page.component';
import { FolderDocumentViewComponent } from './documents/folder-document-view/folder-document-view.component';
import { TrainingReportPageComponent } from './documents/training-report-page/training-report-page.component';
import { CreateTrainingCampaignComponent } from './documents/create-training-campaign/create-training-campaign.component';
import { CreateTrainingGroupComponent } from './documents/create-training-group/create-training-group.component';
import { EditTrainingCampaignComponent } from './documents/edit-training-campaign/edit-training-campaign.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentManagementComponent } from './incident/incident-management/incident-management.component';
import { CreateIncidentComponent } from './incident/create-incident/create-incident.component';
import { IncidentDetailsComponent } from './incident/incident-details/incident-details.component';
import { ImportDocumentComponent } from './documents/import-document/import-document.component';
import { EditDocumentsComponent } from './documents/edit-documents/edit-documents.component';
import { ImportTemplateComponent } from './documents/import-template/import-template.component';
import { FormManagementComponent } from './form-management/form-management.component';
import { ApprovePendingFormComponent } from './form-management/approve-pending-form/approve-pending-form.component';
import { CreateFormManagementComponent } from './form-management/create-form-management/create-form-management.component';
import { FormDetailsPageComponent } from './form-management/form-details-page/form-details-page.component';
import { HomeComponent } from '@app/home/home.component';
import { TrainingReportsComponent } from './documents/training-reports/training-reports.component';
import { ArchiveDocumentComponent } from './documents/archive-document/archive-document.component';
import { DashboardRouteGuard } from './dashboard-route-guard/dashboard-route.guard';
import { CloudServiceProviderComponent } from './cloud-service-provider/cloud-service-provider.component';
import { TestCloudServiceProviderComponent } from './cloud-service-provider/test-cloud-service-provider/test-cloud-service-provider.component';
import { ResourcesComponent } from './cloud-service-provider/resources/resources.component';

const routes: Routes = [
  OrgAdminShell.childRoutes([
    {
      path: 'dashboard',
      component: OrgDashboardComponent,
      canActivate: [DashboardRouteGuard],
      data: { title: extract('Dashboard') }
    },
    { path: 'home', component: HomeComponent },
    { path: 'history', component: OrgDashboardHistoryComponent, data: { title: extract('Dashboard history') } },
    { path: 'events/:review_event_guid', component: EventDetailsComponent, data: { title: extract('EventDetails') } },
    { path: '', redirectTo: 'events', pathMatch: 'full' },
    { path: 'events', component: EventsComponent, data: { title: extract('Tasks'), is_editable: false } },

    {
      path: 'incident',
      component: IncidentComponent,
      children: [
        {
          path: 'incident-management',
          component: IncidentManagementComponent,
          data: { title: extract('Incident Management') }
        },
        { path: '', redirectTo: 'incident-management', pathMatch: 'full' },
        {
          path: 'incident-author',
          component: IncidentManagementComponent,
          data: { title: extract('Author') }
        },
        {
          path: 'edit-incident',
          component: CreateIncidentComponent,
          data: { title: extract('Edit Incident') }
        },
        {
          path: 'reviewer',
          component: IncidentManagementComponent,
          data: { title: extract('Reviewer') }
        },
        {
          path: 'incident-approver',
          component: IncidentManagementComponent,
          data: { title: extract('Approver') }
        },
        {
          path: 'incident-details',
          component: IncidentDetailsComponent,
          data: { title: extract('Incident Management') }
        },
        {
          path: 'workspace/:guid',
          component: FolderDocumentViewComponent
        },
        {
          path: 'incident-details/:guid',
          component: IncidentDetailsComponent
        },
        { path: 'create-incident', component: CreateIncidentComponent, data: { title: extract('Create Incident') } }
      ]
    },
    {
      path: 'form',
      component: FormManagementComponent,
      children: [
        { path: '', redirectTo: 'pending-form', pathMatch: 'full' },
        {
          path: 'pending-form',
          component: ApprovePendingFormComponent,
          data: { title: extract('Pending Form') }
        },
        {
          path: 'edit-form',
          component: CreateFormManagementComponent,
          data: { title: extract('Edit Form') }
        },
        {
          path: 'form-details/:id',
          component: FormDetailsPageComponent,
          data: { title: extract('Form Details') }
        },
        {
          path: 'form-details/:guid',
          component: FormDetailsPageComponent
        },
        {
          path: 'form-details',
          component: ApprovePendingFormComponent
        },
        {
          path: 'approved-form',
          component: ApprovePendingFormComponent,
          data: { title: extract('Approved Form') }
        },
        {
          path: 'create-form-management',
          component: CreateFormManagementComponent,
          data: { title: extract('Create Form Management') }
        },
        {
          path: 'workspace/:guid',
          component: FolderDocumentViewComponent
        }
      ]
    },
    {
      path: 'documents',
      component: DocumentsComponent,
      children: [
        {
          path: 'attention-required',
          component: AttentionRequiredComponent,
          data: { title: extract('Attention Required') }
        },
        { path: '', redirectTo: 'attention-required', pathMatch: 'full' },
        {
          path: 'attention-required/:in_progress',
          component: DocumentDetailPageComponent
        },
        {
          path: 'edit-documents',
          component: EditDocumentsComponent,
          data: { title: extract('Edit Document') }
        },

        { path: 'create-document', component: CreateDocumentComponent, data: { title: extract('Create Document') } },
        { path: 'create-folder', component: CreateFolderComponent, data: { title: extract('Create Folder') } },
        {
          path: 'import-document',
          component: ImportDocumentComponent,
          data: { title: extract('Import Document') }
        },
        {
          path: 'import-template',
          component: ImportTemplateComponent,
          data: { title: extract('Import Template') }
        },

        {
          path: 'create-training-group',
          component: CreateTrainingGroupComponent,
          data: { title: extract('Create Training Campaign') }
        },
        {
          path: 'create-sub-folder',
          component: CreateSubFolderComponent,
          data: { title: extract('Create Sub Folder') }
        },
        { path: 'author', component: AttentionRequiredComponent, data: { title: extract('Author') } },
        {
          path: 'author/:in_progress',
          component: DocumentDetailPageComponent
        },
        { path: 'approver', component: AttentionRequiredComponent, data: { title: extract('Approver') } },
        {
          path: 'approver/:in_progress',
          component: DocumentDetailPageComponent
        },
        { path: 'editor', component: AttentionRequiredComponent, data: { title: extract('Editor') } },
        {
          path: 'editor/:in_progress',
          component: DocumentDetailPageComponent
        },
        { path: 'forms', component: FormsComponent, data: { title: extract('Forms') } },
        { path: 'create-form', component: CreateFormComponent, data: { title: extract('Create Form') } },
        { path: 'notifications', component: NotificationsComponent, data: { title: extract('Notifications') } },
        {
          path: 'published-documents',
          component: PublishedDocumentComponent,
          data: { title: extract('Published Documents') }
        },
        {
          path: 'published-documents/:document_version',
          component: DocumentDetailPageComponent
        },
        {
          path: 'workspace/:guid',
          component: FolderDocumentViewComponent
        },
        {
          path: 'detailPage/:guid',
          component: DocumentDetailPageComponent
        },
        { path: 'vendors', component: VendorManagementComponent, data: { title: extract('Vendor Management') } },
        { path: 'vendors/:guid', component: VendorManagementComponent, data: { title: extract('Vendor Management') } },

        {
          path: 'compliance-management',
          component: ComplianceManagementComponent,
          data: { title: extract('Compliance Management') }
        },
        { path: 'risk-management', component: RiskManagementComponent, data: { title: extract('Risk Management') } },
        { path: 'it-management', component: ItManagementComponent, data: { title: extract('IT Management') } },
        { path: 'retired-documents', component: RetiredDocsComponent, data: { title: extract('Retired Documents') } },
        { path: 'templates', component: TemplatesComponent, data: { title: extract('Templates') } },
        { path: 'create-template', component: CreateTemplateComponent, data: { title: extract('Create Template') } },
        { path: 'archive-document', component: ArchiveDocumentComponent, data: { title: extract('Archive Documents') } }
      ]
    },
    {
      path: 'create-training-campaign',
      component: CreateTrainingCampaignComponent,
      data: { title: extract('Create Training Campaign') }
    },
    {
      path: 'edit-training-campaign',
      component: EditTrainingCampaignComponent,
      data: { title: extract('Edit Training Campaign') }
    },

    { path: 'training', component: TrainingComponent, data: { title: extract('Training') } },
    { path: 'training', component: TrainingComponent, data: { title: extract('Training') } },
    {
      path: 'training/:document_version',
      component: TrainingReportPageComponent
    },
    {
      path: 'report',
      component: TrainingReportsComponent,
      data: { title: extract('Report') }
    },
    {
      path: 'cloud',
      component: CloudServiceProviderComponent,
      data: { title: extract('Cloud') },
      children: [
        {
          path: 'test-cloud-service-provider',
          component: TestCloudServiceProviderComponent,
          data: { title: extract('Tests') }
        },
        {
          path: 'resources',
          component: ResourcesComponent,
          data: { title: extract('Resources') }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrganisationAdminRoutingModule {}
