import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { map, concatMap, mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { ArchiveDocuments } from './archive-document/archive-document.component';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  objIndex = 0;
  completeData: any = [];
  iitem: any = [];
  navchange: EventEmitter<number> = new EventEmitter();
  itemChecked: EventEmitter<any> = new EventEmitter();
  itemCheckedGuid: EventEmitter<any> = new EventEmitter();
  templateChecked: EventEmitter<any> = new EventEmitter();

  selectedFrameworks$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedConnectors$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedAccounts$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedResources$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedServices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  cloudProvidersSubject = new BehaviorSubject<{ active_account: any[]; available_account: any[] }>({
    active_account: [],
    available_account: []
  });

  currenctChecked: String;
  template_guid: any;
  template_name: any;
  document_guid: any;
  folderGuid: any;

  getTomorrowDate(): any {
    const currentDate = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(currentDate.getDate() + 1);

    return {
      year: tomorrowDate.getFullYear(),
      month: tomorrowDate.getMonth() + 1,
      day: tomorrowDate.getDate()
    };
  }
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  private selectedDocuments: Subject<any> = new Subject<any>();
  selectedDocuments$: Observable<any> = this.selectedDocuments.asObservable();

  private shareSelectedFolder: Subject<any> = new Subject<any>();
  shareSelectedFolder$: Observable<any> = this.shareSelectedFolder.asObservable();

  private setSelectedFolderGuid: Subject<any> = new Subject<any>();
  setSelectedFolderGuid$: Observable<any> = this.setSelectedFolderGuid.asObservable();
  public folderName: any = new BehaviorSubject<any>([]);
  public editDocumentGuid: any = new BehaviorSubject<any>([]);
  public isImportDocument: any = new BehaviorSubject<any>([]);
  publishedDocumentsBehavour: any = new BehaviorSubject({ isUpdated: true, publishedDocuments: [] });
  // currentOpen: EventEmitter<Boolean> = new EventEmitter();
  // currenctChecked: String;
  // isOpen:String;
  // private themeSubject = new BehaviorSubject<completeData>();
  // this.themeSubject.next(theme);
  list = [
    {
      checked: Boolean,
      children: [
        {
          description: String,
          guid: String,
          parent_folder: String,
          title: String
        }
      ],
      description: String,
      guid: String,
      id: Number,
      org_uuid: String,
      title: String
    }
  ];
  checkedItem: any;
  checkedGuid: any;
  guid: any;
  workspaceData: any;
  constructor(private http: HttpClient) {}

  setData(updatedData: any) {
    this.sharedData.next(updatedData);
  }

  setSelectedFolderData(updatedData: any) {
    this.shareSelectedFolder.next(updatedData);
  }

  setSelectedFolderdataGuid(updateGuid: any) {
    this.setSelectedFolderGuid.next(updateGuid);
  }

  setSelectedDocsData(selectedDocumentsData: any) {
    this.selectedDocuments.next(selectedDocumentsData);
  }

  getSelectedDocsData(): Observable<any> {
    return this.selectedDocuments.asObservable();
  }
  setworkspaceData(data: any) {
    this.workspaceData = data;
  }
  getworkspaceData() {
    return this.workspaceData;
  }

  submitOrganisationAddDocumentData(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/`, payload, {
      withCredentials: true
    });
  }
  submitOrganisationEditDocumentData(org_uuid: string, payload: any, guid: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/document/${guid}/`, payload, {
      withCredentials: true
    });
  }

  submitOrganisationImportDocumentData(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/create_published_document/`, payload, {
      withCredentials: true
    });
  }

  createCampaign(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/training_campaign/`, payload, {
      withCredentials: true
    });
  }

  edittedCampaignDetails(org_uuid: string, payload: any, guid: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/training_campaign/${guid}/`, payload, {
      withCredentials: true
    });
  }

  createTrainingGroup(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/training_group/`, payload, {
      withCredentials: true
    });
  }
  createDocumentGroup(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document_group/`, payload, {
      withCredentials: true
    });
  }

  submitOrganisationTemplate(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/templates/`, payload, {
      withCredentials: true
    });
  }

  // Folder list api call
  getOrganizationFolderList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/`, {
      withCredentials: true
    });
  }

  reOpenCampaignOnClick(org_uuid: string, guid: any, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/training_campaign/${guid}/reopen_campaign/`, payload, {
      withCredentials: true
    });
  }

  getOrganizationFolderListForWorkspace(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace/`, {
      withCredentials: true
    });
  }

  getOrganizationFolderListPagination(org_uuid: string, page?: number): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/?page=${page}`, {
      withCredentials: true
    });
  }

  // Folder create api call
  SubmitDocumentFolderData(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/folder/`, payload, {
      withCredentials: true
    });
  }

  // SubFolder list api call
  getOrganizationSubFolderList(org_uuid: string, folder_id: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/${folder_id}/`, {
      withCredentials: true
    });
  }

  // document types list api call
  getOrganizationDocumentTypesList(): Observable<any> {
    return this.http.get(`/api/document_types/`, {
      withCredentials: true
    });
  }

  // document store ID api call
  getDocStoreID(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/`, { withCredentials: true });
  }

  // documents list api calls
  getOrganizationDocumentsList(
    org_uuid: string,
    search_title: string,
    page: number,
    page_size: number = 10,
    status: string,
    role: string,
    customFilterString: string = '',
    endPoint: string = 'document'
  ): Observable<any> {
    if (org_uuid != null) {
      let url = customFilterString.length
        ? `/api/organizations/${org_uuid}/${endPoint}/?role=${role}&page=${page}&count=${page_size}&search_title=${search_title}&status=${status}&${customFilterString}`
        : `/api/organizations/${org_uuid}/${endPoint}/?role=${role}&page=${page}&count=${page_size}&search_title=${search_title}&status=${status}`;
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }

  getOrganisationStatus(roleFilter: string): Observable<any> {
    const url = `/api/document-status/?role=${roleFilter}`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getOverallDocumentTabCount(org_uuid: string): Observable<any> {
    let url = `/api/organizations/${org_uuid}/document/document_counts/`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getOverallDocumentStatusCount(org_uuid: string, customFilterString: string = ''): Observable<any> {
    let url = customFilterString.length
      ? `/api/organizations/${org_uuid}/document/get_document_status_count/?` + customFilterString
      : `/api/organizations/${org_uuid}/document/get_document_status_count/`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getOrganizationDocumentsListPagination(org_uuid: string, page: number, role: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/document/?page=${page}&role=${role}`, {
        withCredentials: true
      });
    }
  }

  getPublishedDocumentLink(orgGuid: string, docguid: string): Observable<any> {
    let url = `/api/organizations/${orgGuid}/document/${docguid}/update_collab_task_on_view_document/`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getSearchResultList(
    org_uuid: string,
    search_title: string,
    page: number,
    status: string,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?role=${role}&page=${page}&search_title=${search_title}&status=${status}`,
      {
        withCredentials: true
      }
    );
  }

  getSearchResultListForAttentionRequired(
    org_uuid: string,
    search_title: string,
    page: number,
    status: string,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?page=${page}&search_title=${search_title}&status=${status}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }

  getSearchResultListForTrainingCampaign(org_uuid: string, search_title: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_campaign/?search=${search_title}`, {
      withCredentials: true
    });
  }

  getSearchResultListForTrainingDocuments(org_uuid: string, search_title: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_tracker/?search=${search_title}`, {
      withCredentials: true
    });
  }

  getSearchResultListForTrainingList(
    org_uuid: string,
    search_title: string,
    page: number,
    status: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?page=${page}&search_title=${search_title}&status=${status}`,
      {
        withCredentials: true
      }
    );
  }
  getOrganizationTrainingDocumentsList(org_uuid: string, page: number): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/training_campaign/?page=${page}`, {
        withCredentials: true
      });
    }
  }

  getOrganizationTrainingDocumentsListPagination(
    org_uuid: string,
    page: number,
    search_title?: any,
    status?: string
  ): Observable<any> {
    let queryParams = ``;
    if (search_title) {
      queryParams += `&search=${search_title}`;
    }
    if (status) {
      queryParams += `&${status}`;
    }
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/training_campaign/?page=${page}${queryParams}`, {
        withCredentials: true
      });
    }
  }

  retriveCampaignData(org_uuid: string, guid: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/training_campaign/${guid}/`, {
        withCredentials: true
      });
    }
  }

  getNotificationData(org_uuid: string, status: any, search: any, page: number): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/notifications/?status=${status}&search=${search}&page=${page}`,
        {
          withCredentials: true
        }
      );
    }
  }

  getIncidentReviewerList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/reviewers/`, {
      withCredentials: true
    });
  }

  getIncidentApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/approvers/`, {
      withCredentials: true
    });
  }

  getFilteredYearList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/${doc_uuid}/years_list/`, {
      withCredentials: true
    });
  }

  getJiraTicketsAssignees(org_uuid: string, guid: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/${guid}/`, {
        withCredentials: true
      });
    }
  }

  getJiraTickets(org_uuid: string, guid: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/${guid}/get_tickets/`, {
        withCredentials: true
      });
    }
  }

  // getOrganizationTrainingTrackerList(org_uuid: string): Observable<any> {
  getOrganizationTrainingTrackerList(org_uuid: string, page: number): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/training_tracker/?page=${page}`, {
        withCredentials: true
      });
    }
  }

  getOrganizationTrainingTrackerListPagination(
    org_uuid: string,
    page: number,
    search_title: any,
    status?: string
  ): Observable<any> {
    let queryParams = ``;
    if (search_title) {
      queryParams += `&search=${search_title}`;
    }
    if (status) {
      queryParams += `&${status}`;
    }
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/training_tracker/?page=${page}${queryParams}`, {
        withCredentials: true
      });
    }
  }

  getFolderDocumentViewList(
    org_uuid: string,
    folder_id: any,
    customFilterString: string = '',
    workspace_template_type?: string
  ): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/folder/${folder_id}/tree/`;
      if (workspace_template_type) {
        url = url + '?workspace_template_type=' + workspace_template_type;
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }
  getFolderDocumentViewListPagination(
    org_uuid: string,
    folder_id: any,
    page: number,
    search_title: any,
    page_size: number = 10,
    customFilterString: string = '',
    template_type: string = '',
    month: string = '',
    year: string = '',
    type: string = ' '
  ): Observable<any> {
    let url = `/api/organizations/${org_uuid}/folder/${folder_id}/tree/?page=${page}&count=${page_size}&search_title=${search_title}&${customFilterString}&workspace_template_type=${type}`;
    url = template_type ? url + `&template_type=${template_type}` : url;
    url = month.length ? url + `&month=${month}` : url;
    url = year.length ? url + `&year=${year}` : url;
    if (org_uuid != null) {
      return this.http.get(url, {
        withCredentials: true
      });
    }
    return this.http.get(url, {
      withCredentials: true
    });
  }

  getPaginatedFolderDocumentViewList(org_uuid: string, folder_id: any, event: any, search_title: any): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/folder/${folder_id}/tree/?page=${event}&search_title=${search_title}`,
      {
        withCredentials: true
      }
    );
  }

  getSearchList(
    org_uuid: string,
    folder_id: any,
    search_title: string,
    page: number,
    template_type: string = '',
    month: string = '',
    year: string = '',
    type: string = ''
  ): Observable<any> {
    let url = template_type
      ? `/api/organizations/${org_uuid}/folder/${folder_id}/tree/?page=${page}&search_title=${search_title}&template_type=${template_type}&workspace_template_type=${type}`
      : `/api/organizations/${org_uuid}/folder/${folder_id}/tree/?page=${page}&search_title=${search_title}&workspace_template_type=${type}`;
    url = month.length ? url + `&month=${month}` : url;
    url = year.length ? url + `&year=${year}` : url;
    return this.http.get(url, {
      withCredentials: true
    });
  }

  // documents list for Attention Required api calls
  getOrganizationAttentionDocumentsList(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/document/`, {
        withCredentials: true
      });
    }
  }

  getPaginatedDocumentsList(org_uuid: string, event: any, search_title: any, status: string): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?page=${event}&search_title=${search_title}&status=${status}`,
      {
        withCredentials: true
      }
    );
  }

  getPaginatedDocumentsList2(org_uuid: string, event: any, search_title: any): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/?page=${event}&search_title=${search_title}`, {
      withCredentials: true
    });
  }

  getPaginatedDocumentsList1(
    org_uuid: string,
    role: string,
    event: any,
    search_title: any,
    status: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?page=${event}&search_title=${search_title}&status=${status}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }

  getPaginationDocumentApi(org_uuid: string, event: any, search_title: any, status: string): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?page=${event}&search_title=${search_title}&status=${status}/`,
      {
        withCredentials: true
      }
    );
  }

  getCompleteData() {
    return this.completeData;
  }
  setCompleteData(data: any) {
    this.completeData = data;
  }
  emitNavChangeEvent(completeData: any) {
    this.completeData = completeData;
    this.navchange.emit(completeData);
  }
  setTemplateName(name: any) {
    this.template_name = name;
  }

  setTemplateGuid(guid: any) {
    this.template_guid = guid;
  }

  getTemplateName() {
    return this.templateChecked;
  }

  getTemplate() {
    return this.template_name;
  }

  getTemplateGuid() {
    return this.template_guid;
  }

  setFolderGuid(guid: any) {
    this.folderGuid = guid;
  }

  getFolderGuid() {
    return this.folderGuid;
  }

  routingData(guid: any) {
    this.setFolderGuid(guid);
  }

  emitTemplateName(template_name: any) {
    this.completeData = template_name;
    this.templateChecked.emit(template_name);
  }
  emitEventWithLatestTemplateData(template_name: any) {
    this.templateChecked.emit(template_name);
  }
  getItemCheckedTemplate() {
    return this.templateChecked;
  }
  emitCheckedItem(currenctChecked: any) {
    this.iitem = currenctChecked;
    this.itemChecked.emit(currenctChecked);
  }
  // emitOpenAndClose(isOpen: any) {
  //   this.iitem = isOpen;
  //   this.itemChecked.emit(isOpen);
  // }
  emitCheckedItemGuid(guid: any) {
    this.iitem = guid;
    this.itemCheckedGuid.emit(guid);
  }
  getNavChangeEmitter() {
    return this.navchange;
  }
  getItemChecked() {
    return this.itemChecked;
  }
  // getItemOpenAndClose() {
  //   return this.currentOpen;
  // }
  getItemCheckedGuid() {
    return this.itemCheckedGuid;
  }
  getcheckedItem() {
    return this.currenctChecked;
  }
  // getOpen() {
  //   return this.isOpen;
  // }
  getcheckedItemGuid() {
    return this.guid;
  }
  setcheckedItem(data: any) {
    this.currenctChecked = data;
  }
  // setOpen(data: any) {
  //   this.currenctChecked = data;
  // }

  selectedCheckedGuid(data: any) {
    this.guid = data;
  }
  emitEventWithLatestData(currenctChecked: any) {
    this.itemChecked.emit(currenctChecked);
  }
  // emitEventWithOpenAndClose(isOpen: any) {
  //   this.currentOpen.emit(isOpen);
  // }
  emitEventWithLatestDataGuid(guid: any) {
    this.itemCheckedGuid.emit(guid);
  }
  updateTemplates(iitem: { id: any }, event: any): void {
    this.setTemplateName(iitem['title']);
    this.setTemplateGuid(iitem['guid']);
    // this.selectedCheckedGuid(iitem['guid']);

    const id = iitem.id;
    const guid = iitem['guid'];
    const org_uuid = iitem['org_uuid'];

    //call api and check if it has some data

    //if yes then

    //find the element and update its children with new data and update the id of all children
  }
  updateReplies(iitem: { id: any }, event: any, isOpen: Boolean): void {
    this.setcheckedItem(iitem['title']);
    this.selectedCheckedGuid(iitem['guid']);
    // this.setOpen(iitem['isOpen']);

    const id = iitem.id;
    const guid = iitem['guid'];
    const org_uuid = iitem['org_uuid'];

    //call api and check if it has some data

    this.getOrganizationSubFolderList(org_uuid, guid).subscribe((data: any) => {
      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          // data[i]['id'] = id ;
          data[i]['id'] = id + '.' + i;
          data[i]['org_uuid'] = org_uuid;
          data[i]['children'] = [];
          data[i]['checked'] = false;
          // data[i]['isOpen'] = false;
        }
        for (let i = 0; i < data.length; i++) {
          this.objIndex = 0;
          this.findPropertyAnywhereAndPush(this.completeData, id, iitem, data[i]);
        }
      }
    });
    //if yes then

    //find the element and update its children with new data and update the id of all children
  }

  updateData(iitem: { id: any }): void {
    // this.setcheckedItem(iitem['title']);
    // this.selectedCheckedGuid(iitem['guid']);
    // this.setOpen(iitem['isOpen']);

    const id = iitem.id;
    const guid = iitem['guid'];
    const org_uuid = iitem['org_uuid'];

    //call api and check if it has some data

    this.getOrganizationSubFolderList(org_uuid, guid).subscribe((data: any) => {
      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          // data[i]['id'] = id ;
          data[i]['id'] = id + '.' + i;
          data[i]['org_uuid'] = org_uuid;
          data[i]['children'] = [];
          data[i]['checked'] = false;
          // data[i]['isOpen'] = false;
        }
        // for (let i = 0; i < data.length; i++) {
        //   this.objIndex = 0;
        //   this.findPropertyAnywhereAndPush(this.completeData, id, iitem, data[i]);
        // }
      }
    });
    //if yes then

    //find the element and update its children with new data and update the id of all children
  }

  findPropertyAnywhereAndPush(obj: any, id: any, currentObj: any, data: any) {
    const currId = currentObj.id;
    const returnvalue = this.checkIfITisPresetnAtThisLevel(obj, id, data);
    if (returnvalue !== -1) {
      return;
    }
    let currIDSplit = id.toString().split('.')[this.objIndex];
    // if (currentObj.id.toString().split('.')[this.objIndex] === currIDSplit) {
    this.objIndex = this.objIndex + 1;
    this.findPropertyAnywhereAndPush(obj[currIDSplit]['children'], id, obj[currIDSplit], data);
    // }
    return;
  }

  checkIfITisPresetnAtThisLevel(obj: any, id: any, data: any) {
    let index = 0;
    for (let item of obj) {
      index = index + 1;
      if (item.id === id) {
        item['children'].push(data);
        return index;
      }
    }
    return -1;
  }
  getReplies(): any[] {
    return this.completeData;
  }

  // Invite Document Editor api call
  submitInviteDocumentEditorData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/document_user_relationship/`, payload, {
      withCredentials: true
    });
  }

  // Invite Document Editor api call
  // getDocumentEditorList(org_uuid: string, doc_uuid: string): Observable<any> {
  //   return this.http.get(
  //     `/api/organizations/${org_uuid}/document/1dc4fbd6-358e-4d1f-ba29-bb1c5bdbb886/get_document_editors`,
  //     {
  //       withCredentials: true
  //     }
  //   );
  // }
  // getDocumentEditorList(org_uuid: string, doc_uuid: string): Observable<any> {
  //   return this.http.get(
  //     `/api/organizations/${org_uuid}/document/1dc4fbd6-358e-4d1f-ba29-bb1c5bdbb886/get_document_editors`,
  //     {
  //       withCredentials: true
  //     }
  //   );
  // }
  getDocumentEditorList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/get_document_editors/`, {
      withCredentials: true
    });
  }
  getListUnassignedEditors(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/list_unassigned_editors/`, {
      withCredentials: true
    });
  }
  getListUnassignedApprovers(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/list_unassigned_approvers/`, {
      withCredentials: true
    });
  }

  // Invite Document Approver api call
  getDocumentApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/get_document_approvers/`, {
      withCredentials: true
    });
  }

  submitNewGroupUser(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_group/${doc_uuid}/add/`, {
      withCredentials: true
    });
  }

  // Mark Edit as Done api call
  submitMarkEditingDoneData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/mark_editing_done/`, payload, {
      withCredentials: true
    });
  }
  submitMarkEditDoneForForm(orgGuid: string = '', formGuid: string = '', payload: any = {}) {
    payload = {
      remarks: payload.remarks || '',
      status: 'submitted'
    };
    return this.http.post(`/api/organizations/${orgGuid}/form/${formGuid}/update_status/`, payload, {
      withCredentials: true
    });
  }

  // Request Change api call
  submitChangeRequestData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/request_changes/`, payload, {
      withCredentials: true
    });
  }

  // Approve changes api call
  submitApproveChangesData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/approve_changes/`, payload, {
      withCredentials: true
    });
  }
  submitApproveChangesFormData(org_uuid: string, formGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/form/${formGuid}/approve_form/`, payload, {
      withCredentials: true
    });
  }

  // Revoke Editor/Approver api call
  submitRevokeEditorApproverData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/revoke_collaboration/`, payload, {
      withCredentials: true
    });
  }

  submitDelGroupUserData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/training_group/${doc_uuid}/remove/`, payload, {
      withCredentials: true
    });
  }

  // Move to In Edit api call
  submitMoveToInEditData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/mark_in_edit/`, payload, {
      withCredentials: true
    });
  }

  // Publish Document api call
  submitPublishDocumentData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/publish_document/`, payload, {
      withCredentials: true
    });
  }

  // Organisations templates list api call
  getOrganisationsTemplatesList(org_uuid: string, page: number, type: string): Observable<any> {
    let url = `/api/organizations/${org_uuid}/templates/?page=${page}`;
    if (type) {
      url = url + '&template_type=' + type;
    }
    return this.http.get(url, {
      withCredentials: true
    });
  }

  // Document Details api call
  getDocumentDetail(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/get_document_info/`, {
      withCredentials: true
    });
  }

  getDocumentDetailPage(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document_version/${doc_uuid}/`, {
      withCredentials: true
    });
  }

  // Document Edit Url api call
  getOrganizationDocumentEditURl(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/edit_document/`, payload, {
      withCredentials: true
    });
  }
  getOrganizationFormEditURl(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${doc_uuid}/edit_document/`, {
      withCredentials: true
    });
  }
  getFormDetailsEditUrl(org_uuid: string, formGuid: string, payload: any = {}): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/form/${formGuid}/update_status/`, payload, {
      withCredentials: true
    });
  }
  // Vendor MAnagement list API call
  getOrganizationVendorManagementsList(org_uuid: string, workspace_element_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/${workspace_element_guid}/tree/`, {
      withCredentials: true
    });
  }

  // Compliance Management list API call
  getOrganizationComplianceManagementsList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace/compliance management/`, {
      withCredentials: true
    });
  }

  // Risk Management list API call
  getOrganizationRiskManagementsList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace/risk management/`, {
      withCredentials: true
    });
  }

  getSearchResultListForPublishedDocuments(
    org_uuid: string,
    search_title: string,
    page: number,
    status: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/published_docs/?page=${page}&search_title=${search_title}`,
      {
        withCredentials: true
      }
    );
  }

  getSearchResultListForDcoumentGroups(org_uuid: string, search_title: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/published_docs/?search_title=${search_title}`, {
      withCredentials: true
    });
  }

  getSearchAndFilterList(
    org_uuid: string,
    search_title: string,
    status: string,
    page: number,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?search_title=${search_title}&status=${status}&page=${page}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }

  getSearchAndFilterListForAttentionRequired(
    org_uuid: string,
    search_title: string,
    status: string,
    page: number,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/document/?search_title=${search_title}&status=${status}&page=${page}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }

  getFilterResultList(org_uuid: string, status: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/?status=${decodeURIComponent(status)}`, {
      withCredentials: true
    });
  }

  // getTemplateGuid() {
  //   return this.template_guid;
  // }

  getAllDocumentVersions(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/document/published_docs/`, {
        withCredentials: true
      });
    }
  }

  getAllPublishedDocsLite(org_uuid: string, value: Boolean): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/document/published_docs_lite/?isTrainingCamp=${value}`, {
        withCredentials: true
      });
    }
  }

  getAllDocumentGroups(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/document_group/`, {
        withCredentials: true
      });
    }
  }

  getAllDocumentVersionsPagination(org_uuid: string, page: number, search_title: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/document/published_docs/?page=${page}&search_title=${search_title}`,
        {
          withCredentials: true
        }
      );
    }
  }
  getDocumentGroups(org_uuid: string, page: number = 1, fetch_documents: any, search_title: any = '') {
    return this.http.get(
      `/api/organizations/${org_uuid}/document_group/?page=${page}&fetch_documents=${fetch_documents}&search_title=${search_title}`,
      {
        withCredentials: true
      }
    );
  }
  getDocumentList(org_uuid: string, page: number = 1, fetch_documents: any, search_title: any = '', guid: any) {
    return this.http.get(
      `/api/organizations/${org_uuid}/document_group/?page=${page}&search_title=${search_title}&fetch_documents=${fetch_documents}&guid=${JSON.stringify(
        guid
      )}`,
      {
        withCredentials: true
      }
    );
  }

  deleteDocumentGroup(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/document_group/${doc_uuid}/remove_document_group/`,
      {},
      {
        withCredentials: true
      }
    );
  }

  getAllgroups(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      let data = this.http.get(`/api/organizations/${org_uuid}/training_group/`, {
        withCredentials: true
      });
      return data;
    }
  }
  // Risk Management list API call
  getOrganizationITManagementsList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace/it management/`, {
      withCredentials: true
    });
  }

  SubmitVesrionDetails(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/create_new_version/`, payload, {
      withCredentials: true
    });
  }

  submitNoChangeData(org_uuid: string, guid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/notifications/${guid}/unchanged/`, payload, {
      withCredentials: true
    });
  }
  getOrganizationTemplatesList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace/templates/`, {
      withCredentials: true
    });
  }

  // Document search list API call
  OrganizationDocumentSearchAPI(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/?search=${doc_uuid}`, {
      withCredentials: true
    });
  }

  uploadDocument(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/upload/`, payload, {
      withCredentials: true
    });
  }

  uploadDocumentToBox(url: string, file: any, access_token: any, attributes: any): Observable<any> {
    const formData = new FormData();
    formData.append('attributes', JSON.stringify(attributes));
    formData.append('file', file);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
      attributes: JSON.stringify(attributes)
    });

    return this.http.post(url, formData, {
      headers: headers
    });
  }
  // Invite Document Editor api call
  submitChangeDuedateFromData(org_uuid: string, doc_uuid: string, doc_guid: string, payload: any): Observable<any> {
    return this.http.put(
      `/api/organizations/${org_uuid}/document/${doc_uuid}/edit_collaboration/${doc_guid}/`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  sendSelectedDocsToCreateTraining(selectedDocuments: any): Observable<any> {
    // const formData = new FormData();
    // formData.append('attributes', JSON.stringify(attributes));
    // formData.append('file', file);
    // const headers = new HttpHeaders({
    //   Authorization: `Bearer ${access_token}`,
    //   attributes: attributes
    // });

    return selectedDocuments;
  }
  getWorkspaceTemplatesList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/workspace`, {
      withCredentials: true
    });
  }

  getWorkspaceElements(org_uuid: string, clickedWorkspaceElementGuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/folder/${clickedWorkspaceElementGuid}/tree/`);
  }

  // training APIs

  getTrainingReport(org_uuid: string, training: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_campaign/${training}/report/`, {
      withCredentials: true
    });
  }

  submitCompleteTrainingData(org_uuid: string, campaign_uuid: string, payload: any): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/training_campaign/${campaign_uuid}/complete_training/`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  getTrainingGroupList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_group/`, {
      withCredentials: true
    });
  }

  updateTrainingGroupTitle(org_uuid: string, group_guid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/training_group/${group_guid}/`, payload, {
      withCredentials: true
    });
  }
  updateDocumentGroup(org_uuid: string, group_guid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/document_group/${group_guid}/`, payload, {
      withCredentials: true
    });
  }

  submitStartTrainingData(org_uuid: string, campaign_uuid: string, payload: any): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/training_campaign/${campaign_uuid}/start_training/`,
      payload,
      {
        withCredentials: true
      }
    );
  }
  getAS(page: number): Observable<any> {
    return this.http.get(`https://api.instantwebtools.net/v1/passenger?page=${page}&size=10`).pipe(delay(2000));
  }

  private statusSource = new BehaviorSubject<string>('');
  status = this.statusSource.asObservable();
  setStatus(status: string) {
    this.statusSource.next(status);
  }

  private statusSource1 = new BehaviorSubject<string>('');
  status1 = this.statusSource1.asObservable();
  setStatus1(status1: string) {
    this.statusSource1.next(status1);
  }

  private statusSource2 = new BehaviorSubject<string>('');
  status2 = this.statusSource2.asObservable();
  setStatus2(status2: string) {
    this.statusSource2.next(status2);
  }

  private statusSource3 = new BehaviorSubject<string>('');
  status3 = this.statusSource3.asObservable();
  setStatus3(status3: string) {
    this.statusSource3.next(status3);
  }

  private statusSource4 = new BehaviorSubject<string>('');
  status4 = this.statusSource4.asObservable();
  setStatus4(status4: string) {
    this.statusSource4.next(status4);
  }

  private statusSource5 = new BehaviorSubject<string>('');
  status5 = this.statusSource5.asObservable();
  setStatus5(status5: string) {
    this.statusSource5.next(status5);
  }

  private statusSource6 = new BehaviorSubject<string>('');
  status6 = this.statusSource6.asObservable();
  setStatus6(status6: string) {
    this.statusSource6.next(status6);
  }

  private statusSource7 = new BehaviorSubject<string>('');
  status7 = this.statusSource7.asObservable();
  setStatus7(status7: string) {
    this.statusSource7.next(status7);
  }

  private statusSource8 = new BehaviorSubject<string>('');
  status8 = this.statusSource8.asObservable();
  setStatus8(status8: string) {
    this.statusSource8.next(status8);
  }

  private statusSource9 = new BehaviorSubject<string>('');
  status9 = this.statusSource9.asObservable();
  setStatus9(status9: string) {
    this.statusSource9.next(status9);
  }

  private statusSource10 = new BehaviorSubject<string>('');
  status10 = this.statusSource10.asObservable();
  setStatus10(status10: string) {
    this.statusSource10.next(status10);
  }

  private statusSource11 = new BehaviorSubject<string>('');
  status11 = this.statusSource11.asObservable();
  setStatus11(status11: string) {
    this.statusSource11.next(status11);
  }

  private statusSource12 = new BehaviorSubject<string>('');
  status12 = this.statusSource12.asObservable();
  setStatus12(status12: string) {
    this.statusSource12.next(status12);
  }

  private statusSource13 = new BehaviorSubject<string>('');
  status13 = this.statusSource13.asObservable();
  setStatus13(status13: string) {
    this.statusSource13.next(status13);
  }

  //Add readers data api call
  SubmitAddReadersData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/add_readers/`, payload, {
      withCredentials: true
    });
  }

  //Remove readers data api call
  SubmitRevokeReadersData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/revoke_readers/`, payload, {
      withCredentials: true
    });
  }

  private titleSource = new BehaviorSubject<string>('');
  title = this.titleSource.asObservable();
  setTitle(title: string) {
    this.titleSource.next(title);
  }

  // Rename document title api call
  submitRenameDocumentTitleData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/rename_document_title/`, payload, {
      withCredentials: true
    });
  }

  //Add readers data api call
  check_collaborator(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/check_collaborator/`, {
      withCredentials: true
    });
  }
  checkCollaboratorAdminRole(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${doc_uuid}/check_collaborator_admin_role/`, {
      withCredentials: true
    });
  }
  saveDocumentGroup(org_uuid: string, groupId: string, payload: any = []) {
    return this.http.post(`/api/organizations/${org_uuid}/document_group/${groupId}/add_remove_documents/`, payload, {
      withCredentials: true
    });
  }

  submitFormData(org_uuid: string, form_guid: string = '', payload: any): Observable<any> {
    let api = form_guid ? `/api/organizations/${org_uuid}/form/${form_guid}/` : `/api/organizations/${org_uuid}/form/`;
    let method = form_guid ? 'put' : 'post';
    return this.http[method](api, payload, {
      withCredentials: true
    });
  }

  getAllUnassignedUsers(org_uuid: string, trainingId: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_campaign/${trainingId}/unassigned_training_users/`, {
      withCredentials: true
    });
  }

  getFormData(org_uuid: string, form_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${form_guid}/`, {
      withCredentials: true
    });
  }

  getPendingForm(
    org_uuid: string,
    status: string,
    page: number,
    search_title: any,
    tab_status: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/form/?status=${status}&page=${page}&search_title=${search_title}&tab_status=${tab_status}`,
      {
        withCredentials: true
      }
    );
  }

  getFormResponderList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${doc_uuid}/responders/`, {
      withCredentials: true
    });
  }
  getFormApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${doc_uuid}/approvers/`, {
      withCredentials: true
    });
  }
  getUnassignedApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${doc_uuid}/approvers/?unassigned=True`, {
      withCredentials: true
    });
  }

  getUnassignedResponderList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/${doc_uuid}/responders/?unassigned=True`, {
      withCredentials: true
    });
  }

  submitFormReviewerData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/form/${doc_uuid}/form_user_relationship/`, payload, {
      withCredentials: true
    });
  }
  submitFormChangeDuedate(org_uuid: string, form_uuid: string, collab_guid: string, payload: any): Observable<any> {
    return this.http.put(
      `/api/organizations/${org_uuid}/form/${form_uuid}/edit_form_collaboration/${collab_guid}/`,
      payload,
      {
        withCredentials: true
      }
    );
  }
  submitRevokeResponderApproverData(org_uuid: string, formGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/form/${formGuid}/revoke_collaboration/`, payload, {
      withCredentials: true
    });
  }
  submitInviteFormResponderData(org_uuid: string, formGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/form/${formGuid}/form_user_relationship/`, payload, {
      withCredentials: true
    });
  }
  getTemplateTypeList(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/template_types/`, {
        withCredentials: true
      });
    }
  }
  getaccess(org_uuid: string, docVersion: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/document/${docVersion}/request_access/`, {
      withCredentials: true
    });
  }
  OrganizationFormSearchAPI(org_uuid: string, form_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/form/?search=${form_uuid}`, {
      withCredentials: true
    });
  }
  getOrganizationExportTrainingList(org_uuid: string, page?: number): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/export_training/`;
      if (page) {
        url = url + `?page=${page}`; // Corrected the query parameter
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }
  getCampaignFilterDataList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_report/get_campaigns/`, {
      withCredentials: true
    });
  }
  getUserFilterDataList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_report/get_users/`, {
      withCredentials: true
    });
  }
  getDocumentFilterDataList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_report/get_documents/`, {
      withCredentials: true
    });
  }
  getOrganizationExportTrainingListPagination(
    org_uuid: string,
    page?: any,
    start_date?: any,
    end_date?: any,
    campaigns?: string,
    users?: string,
    documents?: string,
    externalDocs?: string,
    trainingStatus?: any
  ): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/export_training/`;

      // Initialize an array to collect query parameters
      const queryParams = [];
      if (page) {
        queryParams.push(`page=${page}`);
      }
      if (start_date) {
        queryParams.push(`start_date=${start_date}`);
      }
      if (end_date) {
        queryParams.push(`end_date=${end_date}`);
      }
      if (campaigns) {
        queryParams.push(campaigns);
      }
      if (users) {
        queryParams.push(users);
      }
      if (documents) {
        queryParams.push(documents);
      }
      if (externalDocs) {
        queryParams.push(externalDocs);
      }
      if (trainingStatus) {
        queryParams.push(trainingStatus);
      }

      // Check if there are any query parameters to add
      if (queryParams.length > 0) {
        url += (url.includes('?') ? '&' : '?') + queryParams.join('&');
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }
  getOrganizationExportTrainingListFilterwise(
    org_uuid: string,
    start_date?: any,
    end_date?: any,
    campaigns?: string,
    users?: string,
    documents?: string,
    externalDocs?: string,
    traingStatus?: string
  ): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/export_training/`;

      const queryParams = [];
      if (start_date) {
        queryParams.push(`start_date=${start_date}`);
      }
      if (end_date) {
        queryParams.push(`end_date=${end_date}`);
      }
      if (campaigns) {
        queryParams.push(campaigns);
      }
      if (users) {
        queryParams.push(users);
      }
      if (documents) {
        queryParams.push(documents);
      }
      if (externalDocs) {
        queryParams.push(externalDocs);
      }

      if (traingStatus) {
        queryParams.push(traingStatus);
      }

      // Check if there are any query parameters to add
      if (queryParams.length > 0) {
        url += (url.includes('?') ? '&' : '?') + queryParams.join('&');
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }
  getTrainingSharableLink(orgId: string, doc_version_guid: string) {
    console.log(orgId, doc_version_guid);
    return this.http.get(`/api/organizations/${orgId}/document/${doc_version_guid}/shareable_link/`, {
      withCredentials: true
    });
  }

  removeUserFromOngoingTraining(org_uuid: string, guid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/training_campaign/${guid}/remove_trainee/`, payload, {
      withCredentials: true
    });
  }

  //Archived Documents
  getArchiveDocumentsList(org_uuid: string, page: number, search_title: any): Observable<ArchiveDocuments> {
    if (org_uuid != null) {
      return this.http.get<ArchiveDocuments>(
        `/api/organizations/${org_uuid}/document/archive_docs/?page=${page}&search_title=${search_title}`,
        {
          withCredentials: true
        }
      );
    }
  }

  archiveDocument(org_uuid: string, doc_uuid: string, payload?: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/archive_document/`, payload, {
      withCredentials: true
    });
  }

  unarchiveDocument(org_uuid: string, doc_uuid: string, payload?: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/unarchive_document/`, payload, {
      withCredentials: true
    });
  }

  exportDocument(org_guid: string, doc_version_guid: string, payload = {}) {
    return this.http.post(`/api/organizations/${org_guid}/document/${doc_version_guid}/export_document/`, payload, {
      withCredentials: true
    });
  }
  resendTrainingInvitation(org_uuid: string, training_campaign_guid: string, payload?: any): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/training_campaign/${training_campaign_guid}/resend_training_invitation/`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  getTrainingStatus(): Observable<any> {
    return this.http.get<any>(`/api/training_status/`, {
      withCredentials: true
    });
  }

  getTestList(
    org_uuid: string,
    page: number,
    count: number,
    filter: string,
    include_ignored_test: any,
    search_title: string,
    status: string,
    tab_name?: string
  ): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/tests_list/`;
      // Initialize an array to collect query parameters
      const queryParams = [];
      if (page) {
        queryParams.push(`page=${page}`);
      }
      if (status) {
        queryParams.push(`status=${status}`);
      }
      if (count) {
        queryParams.push(`count=${count}`);
      }
      if (include_ignored_test) {
        queryParams.push(`include_ignored_test=${include_ignored_test}`);
      }
      if (search_title) {
        queryParams.push(`search_title=${search_title}`);
      }
      if (tab_name) {
        queryParams.push(`tab_name=${tab_name}`);
      }
      if (filter) {
        queryParams.push(filter);
      }
      // Check if there are any query parameters to add
      if (queryParams.length > 0) {
        url += (url.includes('?') ? '&' : '?') + queryParams.join('&');
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }
  getTestListWithoutPagination(
    org_uuid: string,
    filter: string,
    include_ignored_test: any,
    search_title: string,
    status: string,
    tab_name?: string
  ): Observable<any> {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/tests_list/`;
      // Initialize an array to collect query parameters
      const queryParams = [];
      if (status) {
        queryParams.push(`status=${status}`);
      }
      if (include_ignored_test) {
        queryParams.push(`include_ignored_test=${include_ignored_test}`);
      }
      if (search_title) {
        queryParams.push(`search_title=${search_title}`);
      }
      if (tab_name) {
        queryParams.push(`tab_name=${tab_name}`);
      }
      if (filter) {
        queryParams.push(filter);
      }
      // Check if there are any query parameters to add
      if (queryParams.length > 0) {
        url += (url.includes('?') ? '&' : '?') + queryParams.join('&');
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }
  }

  getStatusCount(
    org_uuid: string,
    filter: string,
    include_ignored_test: any,
    search_title: string,
    status: string,
    tab_name?: string
  ) {
    if (org_uuid != null) {
      let url = `/api/organizations/${org_uuid}/tests_list/get_status_count`;
      // Initialize an array to collect query parameters
      const queryParams = [];
      if (status) {
        queryParams.push(`status=${status}`);
      }
      if (include_ignored_test) {
        queryParams.push(`include_ignored_test=${include_ignored_test}`);
      }
      if (search_title) {
        queryParams.push(`search_title=${search_title}`);
      }
      if (tab_name) {
        queryParams.push(`tab_name=${tab_name}`);
      }
      if (filter) {
        queryParams.push(filter);
      }
      // Check if there are any query parameters to add
      if (queryParams.length > 0) {
        url += (url.includes('?') ? '&' : '?') + queryParams.join('&');
      }
      return this.http.get(url, {
        withCredentials: true
      });
    }

    // return this.http.get(`/api/organizations/${org_uuid}/tests_list/get_status_count`, {
    //   withCredentials: true
    // });
  }

  getAllFrameworks(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/organizationprograms/`, {
      withCredentials: true
    });
  }
  getDashboardCharts(org_uuid: string, queryString: any, includeIgnoredTests: boolean = false) {
    let url = queryString.length
      ? `/api/organizations/${org_uuid}/cc_dashboard/dashboard_data?include_ignored_test=${includeIgnoredTests}&${queryString}`
      : `/api/organizations/${org_uuid}/cc_dashboard/dashboard_data?include_ignored_test=${includeIgnoredTests}`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getHistoryChartData(org_guid = '', queryString: string, days = 7, ignored = false) {
    let url = queryString.length
      ? `/api/organizations/${org_guid}/cc_dashboard/dashboard_history_data/?include_ignored_test=${ignored}&date_range_param=${days}&${queryString}`
      : `/api/organizations/${org_guid}/cc_dashboard/dashboard_history_data/?include_ignored_test=${ignored}&date_range_param=${days}`;
    return this.http.get(url, {
      withCredentials: true
    });
  }
  getAllConnectors(org_uuid: string): Observable<any> {
    return this.http.get(`/api/connectors/`, {
      withCredentials: true
    });
  }

  getAllAccounts(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/get_accounts/`, {
      withCredentials: true
    });
  }

  getAllResouces(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/get_resources/`, {
      withCredentials: true
    });
  }

  getAllCloudProvider(org_uuid: string, search_title: string): Observable<any> {
    let queryParams: string;
    if (search_title) {
      queryParams = `&search_title=${search_title}`;
    }
    return this.http.get(`/api/organizations/${org_uuid}/connectors_list/?${queryParams}`, {
      withCredentials: true
    });
  }

  ignoreTests(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/tests_list/ignore_tests/`, payload, {
      withCredentials: true
    });
  }

  unignoreTests(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/tests_list/unignore_tests/`, payload, {
      withCredentials: true
    });
  }

  getAllServices(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/get_services/`, {
      withCredentials: true
    });
  }
  // getAllServicesndAccounts(org_uuid: string,selectedConnectors: string[]): Observable<any> {
  //   return this.http.get(`/api/organizations/${org_uuid}/get_accounts/?connectors=${selectedConnectors}`, {
  //     withCredentials: true
  //   });
  // }
  getAccountsByConnector(org_uuid: string, selectedConnectors: string[]): Observable<any> {
    let encodedConnectors: string | undefined;
    if (selectedConnectors && selectedConnectors.length > 0) {
      const connectorsJson = JSON.stringify(selectedConnectors);
      encodedConnectors = encodeURIComponent(connectorsJson);
    }
    let url = '';

    if (encodedConnectors) {
      url = `/api/organizations/${org_uuid}/get_accounts/?connectors=${encodedConnectors}`;
    } else {
      url = `/api/organizations/${org_uuid}/get_accounts/`;
    }

    return this.http.get(url, {
      withCredentials: true
    });
  }
  getservicesByConnectorAndAccounts(
    org_uuid: string,
    selectedConnectors: string[],
    selectedAccounts: string[]
  ): Observable<any> {
    let encodedConnectors: string | undefined;
    if (selectedConnectors && selectedConnectors.length > 0) {
      const connectorsJson = JSON.stringify(selectedConnectors);
      encodedConnectors = encodeURIComponent(connectorsJson);
    }
    let encodedAccounts: string | undefined;
    if (selectedAccounts && selectedAccounts.length > 0) {
      const accountsJson = JSON.stringify(selectedAccounts);
      encodedAccounts = encodeURIComponent(accountsJson);
    }
    let url = `/api/organizations/${org_uuid}/get_services/`;

    const params: string[] = [];
    if (encodedConnectors) {
      params.push(`connectors=${encodedConnectors}`);
    }
    if (encodedAccounts) {
      params.push(`accounts=${encodedAccounts}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.http.get(url, {
      withCredentials: true
    });
  }

  getAccountsByCloudProvider(org_uuid: string, guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/connectors_list/${guid}/account_run_history/`, {
      withCredentials: true
    });
  }

  getScheduleChoices(): Observable<any> {
    return this.http.get(`/api/schedule_choices/`, {
      withCredentials: true
    });
  }

  integrateServiceProvider(org_uuid: string, payload: any, file: any): Observable<any> {
    const formData = new FormData();
    if (file) {
      formData.append('gcp_json', file, file.name);
    }
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(`/api/organizations/${org_uuid}/connections_crud/`, formData, {
      withCredentials: true
    });
  }

  reconfigServiceProvider(org_uuid: string, payload: any, guid: any, file: any): Observable<any> {
    const formData = new FormData();
    if (file) {
      formData.append('gcp_json', file, file.name);
    }
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.put(`/api/organizations/${org_uuid}/connections_crud/${guid}/`, formData, {
      withCredentials: true
    });
  }

  testServiceProviderConnection(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/test_connection/?connector_type=AWS`, payload, {
      withCredentials: true
    });
  }

  getConnectionDetails(org_uuid: string, guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/connections_crud/${guid}/`, {
      withCredentials: true
    });
  }

  connectDisconectAccount(org_uuid: string, payload: any, guid: string): Observable<any> {
    return this.http.put(
      `/api/organizations/${org_uuid}/connections_crud/${guid}/connect_disconnect_account/`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  syncAccount(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/run_tests/`, payload, {
      withCredentials: true
    });
  }

  getRunSchedule(org_uuid: string, run_schedule: string): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/connections_crud/calculate_next_runtime/get_next_runtime/?run_schedule=${run_schedule}`,
      {
        withCredentials: true
      }
    );
  }

  uploadJsonFile(org_uuid: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('gcp_json', file, file.name);
    return this.http.post(`/api/organizations/${org_uuid}/test_connection/?connector_type=GCP`, formData, {
      withCredentials: true
    });
  }
}
