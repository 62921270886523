import { Component, OnInit } from '@angular/core';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { ServiceProviderIntegrationComponent } from '../service-provider-integration/service-provider-integration.component';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {
  columns: string[] = ['ACCOUNT NAME', 'SYNC TYPE', 'SYNC LAST RUN', 'NEXT SYNC', 'SYNC STATUS'];
  accountsList: any[];
  selectedAccount: any;
  serviceProvider: any;
  loading: boolean = false;
  OrganisationID: string | null;
  orgGuidSubscription: any;
  constructor(
    private sidenavService: SidenavService,
    private location: Location,
    private modalService: ModalService,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private backendErrorService: BackendErrorService
  ) {}

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.serviceProvider = JSON.parse(localStorage.getItem('activeServiceProvider'));
    if (this.OrganisationID) {
      this.getAccountsByCloudProvider();
    }
  }

  getStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'success':
        return 'success';
      case 'fail':
        return 'danger';
      default:
        return '';
    }
  }

  reConfigure() {
    this.sidenavService.ServiceProviderAccountDetails.next(this.selectedAccount);
    this.sidenavService
      .open(ServiceProviderIntegrationComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          if (this.OrganisationID) {
            this.getAccountsByCloudProvider();
          }
        }
      });
  }

  addHardcodedQueryParams(): void {
    const url = new URL(window.location.href);
    url.searchParams.set('serviceProvider', 'AWS');
    url.searchParams.set('reConfig', 'true');
    this.location.replaceState(url.pathname + url.search);
  }

  disconnectIntegration() {
    this.sidenavService.ServiceProviderAccountDetails.next(this.selectedAccount);
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Disconnect Integration',
        modalType: 'Disconnect Integration',
        centered: true,
        message: `Are you sure you want to disconnect ${
          this.serviceProvider?.description?.short_name?.toLowerCase() == 'aws' ? 'AWS' : 'GCP'
        } integration? This will delete all configured data and cannot be undone`
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getAccountsByCloudProvider();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  getAccountsByCloudProvider() {
    this.loading = true;
    this.documentsService.getAccountsByCloudProvider(this.OrganisationID, this.serviceProvider.guid).subscribe(res => {
      this.accountsList = res;
      this.loading = false;
    });
  }

  connectAccount() {
    let payload = {
      is_active: true
    };
    this.loading = true;
    this.documentsService.connectDisconectAccount(this.OrganisationID, payload, this.selectedAccount?.guid).subscribe(
      res => {
        this.toaster.showSuccess('Account connected successfully');
        this.loading = false;
        this.getAccountsByCloudProvider();
      },
      error => {
        this.loading = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.translate.get(error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }

  syncNow(account: any) {
    let payload = {
      acc_guid: account?.guid
    };
    this.loading = true;
    this.documentsService.syncAccount(this.OrganisationID, payload).subscribe(
      res => {
        this.toaster.showSuccess(res?.message);
        this.loading = false;
        this.getAccountsByCloudProvider();
      },
      error => {
        this.loading = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.translate.get(error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }
}
