import { PasswordResetService } from '@app/password-reset/password-reset.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Logger, AuthenticationService } from '@app/core';
import { environment } from '@env/environment';
import { ProviderDataValidators } from '@app/modules/data-valiidator';
import { ActivatedRoute } from '@angular/router';
const log = new Logger('Signup');

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  loading = false;
  error = '';
  signUpWithGoogleError = '';
  signupForm: FormGroup;
  signupStep: number = 1;
  hide = true;
  timer: number;
  timerInterval: any;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private PasswordResetService: PasswordResetService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['error']) {
        this.signUpWithGoogleError = params['error'];
      }
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }

  ngOnInit() {
    this.createForm();
  }
  startTimer() {
    this.timer = 60; // Set initial time to 60 seconds
    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }
  private createForm() {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.signupForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      // organization_name: ['', Validators.required],
      organization_admin_email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required, ProviderDataValidators.validPasswordCheck()]]
      // website_url: ['', Validators.pattern(urlRegex)]
    });
  }

  signup() {
    if (this.signupForm.invalid) return;
    this.loading = true;
    this.authenticationService.signup(this.signupForm.value).subscribe(
      (response: any) => {
        this.setStep(3);
        this.startTimer();
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        let errorkey = Object.keys(error.error)[0];
        this.error = error.error[errorkey];
        this.error = '*' + this.error;
      }
    );
  }
  setStep(step: number) {
    if (this.signUpWithGoogleError) {
      this.removeOptionalParamFromUrl(window.location.href);
      this.signUpWithGoogleError = '';
    }
    this.signupStep = step;
    this.error = '';
    if (step !== 3) {
      this.signupForm.reset();
    }
  }

  removeOptionalParamFromUrl(url: string) {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.delete('error');
    window.history.replaceState({}, document.title, parsedUrl.toString());
  }

  openGoogleSignupPage() {
    if (this.signUpWithGoogleError) {
      this.removeOptionalParamFromUrl(window.location.href);
      this.signUpWithGoogleError = '';
    }
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    let redirectUri = window.location.origin + '/api/auth/signup/google/';
    if (window.location.origin.includes('127.0.0.1') || window.location.origin.includes('localhost')) {
      // change here
      redirectUri = 'http://127.0.0.1:8000/api/auth/signup/google/';
    }

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
      response_type: 'code',
      client_id: environment.google_sso_client_id,
      redirect_uri: `${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };
    console.log(`${redirectUri}`);
    const urlParams = new URLSearchParams(params).toString();
    window.location.href = `${googleAuthUrl}?${urlParams}`;
  }
  resendPassword() {
    this.loading = true;
    this.PasswordResetService.sendverificationEmail({
      email: this.signupForm.value.organization_admin_email
    }).subscribe(
      res => {
        this.startTimer();
        this.loading = false;
      },
      error => {
        this.loading = false;
        let errorkey = Object.keys(error.error)[0];
        this.error = error.error[errorkey];
      }
    );
  }
}
