<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="d-flex mt-4">
    <div class="w-20">
      <button
        (click)="openCreateFolder()"
        *ngIf="createFolderAccess"
        class="btn-folder cb-button-primary mt-2 mb-2 ml-4"
      >
        Create Folder
      </button>
      <div class="search-inputs mt-2">
        <input
          id="search"
          [(ngModel)]="searchDocumentTitle"
          class=" form-control cb-input-focus cb-input m-0"
          placeholder="Search"
          name="search"
          type="text"
        />
        <i class="fa fa-search search-icon"></i>
      </div>
      <div class="tree-container mt-3">
        <div class="col p-0 search-results">
          <ul class="tree mx-2">
            <ng-container *ngFor="let folder of folderData | filter: searchDocumentTitle">
              <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: folder }"></ng-container>
            </ng-container>
          </ul>
          <ng-template #recursiveList let-folder>
            <li [id]="folder.guid" [ngClass]="{ 'main-folder-border': folder.isMainFolder }">
              <div class="tree-list" [ngClass]="{ selected: isSelected(folder) }">
                <img src="../../../assets/images/core/folder-icon.svg" />
                <span
                  class="title"
                  style=" cursor: pointer;"
                  [ngStyle]="{ 'font-weight': folder.isMainFolder ? '700' : '600' }"
                  (click)="handleFolderClick(folder, false)"
                  title="{{ folder.title }}"
                  >{{ folder.title }}</span
                >

                <i
                  *ngIf="folder.is_child_folder"
                  class="toggle-icon"
                  (click)="handleFolderClick(folder, false)"
                  [ngClass]="isToggled(folder) ? 'fa fa-caret-up' : 'fa fa-caret-right'"
                ></i>
              </div>
              <ul *ngIf="isToggled(folder)" class="child-ul">
                <ng-container *ngFor="let child of folder.children">
                  <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child }"></ng-container>
                </ng-container>
              </ul>
            </li>
          </ng-template>
        </div>
      </div>
      <div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
        <h6 class="font-weight-bold ">
          <!-- <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }"></i> &nbsp; Loading... -->
          <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i>
        </h6>
      </div>
    </div>
    <div class="w-100p">
      <div class="w-100p pl-2">
        <!-- <div class="breadcrumbs" *ngIf="showBreadcrumbs" [innerHTML]="selectedFolderPath"></div> -->
        <!-- <div class="breadcrumbs" *ngIf="showBreadcrumbs">
          <span class="breadcrumb-root" (click)="handleFolderClick(folderPath[0])">{{ folderPath[0].title }}</span>
          <ng-container *ngFor="let folder of folderPath.slice(1)">
            > {{ folder.title }}
          </ng-container>
          </div> -->
        <div class="breadcrumbs" *ngIf="showBreadcrumbs">
          <ng-container *ngFor="let folder of folderPath; let i = index">
            <ng-container *ngIf="i < folderPath.length - 1">
              <span class="breadcrumb-icon" (click)="handleFolderClick(folder)"
                ><img src="../../../assets/images/documents/folder-color-icon.svg"
              /></span>
              <span class="breadcrumb-root" (click)="handleFolderClick(folder)">{{ folder.title }}</span>
              <mat-icon style="font-size: 20px;">keyboard_arrow_right</mat-icon>
            </ng-container>
            <ng-container *ngIf="i === folderPath.length - 1">
              <span class="breadcrumb-icon"><img src="../../../assets/images/core/folder-icon.svg"/></span>
              <span class="path-text title" title="{{ folder.title }}">{{ folder.title }}</span>
            </ng-container>
          </ng-container>
        </div>
        <label class="folder-name mt-2">{{ selectedParentFolderName }}</label>
        <hr />
        <div class="d-flex justify-content-between pr-3">
          <div class="search-input mx-2">
            <input
              id="search"
              [(ngModel)]="searchText"
              class=" form-control cb-input-focus cb-input m-0"
              placeholder="Search"
              name="search"
              type="text"
              (keyup)="updateSearchText($event)"
              (keyDown.enter)="Search(searchText)"
              #titleInput
            />
            <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
          </div>
          <div>
            <button
              *ngIf="filtersSelectedCount"
              type="button"
              class="mx-1 btn btn-sm clear-btn"
              (click)="clearFilters()"
            >
              Clear filters
            </button>
            <button
              class="btn-filter ml-2  position-relative"
              #menuTrigger="matMenuTrigger"
              [class.btn-filter-active]="menuTrigger.menuOpen"
              [matMenuTriggerFor]="filtersPopover"
              (menuClosed)="noFilterAction()"
            >
              Filters<img class="ml-2" src="../../../assets/images/core/filter-funnel.svg" />
              <span *ngIf="filtersSelectedCount" class="dot"></span>
            </button>
            <mat-menu #filtersPopover="matMenu" yPosition="below" class="mat-elevation-z1">
              <div class="filter-container p-0 " (click)="$event.stopPropagation()">
                <div class="filter-header w-100p d-flex justify-content-between">
                  <div class="filter-header-title">
                    Filters
                  </div>
                  <div>
                    <mat-icon class="mat-icon-effect" (click)="menuTrigger.closeMenu(); noFilterAction()"
                      >close</mat-icon
                    >
                  </div>
                </div>
                <div class="d-flex w-100p filters">
                  <div class="col-5 p-0 filter-option">
                    <div
                      *ngFor="let item of filtersMenu"
                      class="cursor-pointer d-flex justify-content-between dark-custom-text filter-item"
                      [class.active-filter-item]="item.active"
                      (click)="setFilter(item)"
                    >
                      <div>
                        {{ item.title }}
                      </div>
                      <div class="mr-1">
                        <mat-icon>arrow_right</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-7 pt-3">
                    <div [ngSwitch]="selectedFilter">
                      <div *ngSwitchCase="'Type'">
                        <div class="dark-custom-text mt-2">Type</div>
                        <div
                          class="form-check mt-2 d-flex align-items-center"
                          *ngFor="let item of document_types"
                          [ngStyle]="{
                            backgroundColor: item['recurrenceSelected'] ? '#E5F7F9' : ''
                          }"
                        >
                          <input
                            class="form-check-input custom-checkbox"
                            type="checkbox"
                            [(ngModel)]="item['typeSelected']"
                            [value]="item.slug"
                            [id]="item.value"
                          />
                          <label class="form-check-label mt-1 filter-checkbox-option-label" [for]="item.value"
                            >{{ item.value }}
                          </label>
                        </div>
                      </div>
                      <div *ngSwitchCase="'Author'">
                        <div class="dark-custom-text mt-2">Author</div>
                        <div class="mt-2 search-people position-relative">
                          <div class="input-group">
                            <div class="input-group">
                              <input
                                id="search"
                                [(ngModel)]="authorSearchText"
                                class=" form-control cb-input-focus search-field cb-input"
                                name="search"
                                type="text"
                                placeholder="Search"
                                #editorInput
                              />
                            </div>
                            <i (click)="searchDocumentByTitle(editorInput.value)" class="fa fa-search search-icon"></i>
                          </div>
                        </div>
                        <div class="w-100p people-column ">
                          <ng-container *ngFor="let item of peopleList | filter: authorSearchText; let i = index">
                            <div
                              class="form-check d-flex align-items-center people-select "
                              *ngIf="item.roles?.includes('document_author')"
                              [ngStyle]="{
                                backgroundColor: item['authorSelected'] ? '#E5F7F9' : ''
                              }"
                            >
                              <div class="d-flex selected-option" (click)="toggleCheckbox(i, 'author')">
                                <input
                                  class="form-check-input custom-checkbox"
                                  type="checkbox"
                                  [value]="item.guid"
                                  [attr.data-index]="i"
                                  [(ngModel)]="item['authorSelected']"
                                />
                                <div class="d-flex cursor-default">
                                  <span
                                    class="initials"
                                    [style.backgroundColor]="getColor(item.first_name + ' ' + item.last_name)"
                                  >
                                    {{ getInitials(item.first_name + ' ' + item.last_name) }}
                                  </span>
                                  <div class="email-username">
                                    <div class="username">{{ item.first_name }} {{ item.last_name }}</div>
                                    <div class="email">({{ item.email }})</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" w-100p ">
                  <hr style="width: 100%;" class="mb-1 mt-0" />
                  <div class="footer-subtitle">
                    {{ filtersSelectedCount + ' filters selected' }}
                  </div>
                  <hr style="width: 100%;" class="my-1 mb-1 mt-0" />
                  <div class="text-left mt-2 filter-footer">
                    <button (click)="applyCustomFilters(); menuTrigger.closeMenu()" class="btn apply-btn ">
                      Apply
                    </button>
                    <button
                      (click)="menuTrigger.closeMenu(); clearFilters()"
                      class="btn btn-light clear-filter-btn bg-white border-0"
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </div>
            </mat-menu>

            <button class="btn-doc ml-2" *ngIf="checkCreateAccess" (click)="openCreateDocument(selectedFolderName)">
              Create Document
            </button>
            <button
              class="btn-doc ml-2"
              style="margin-right: 9px;"
              *ngIf="createFolderAccess"
              (click)="openCreateFolder(selectedFolderName)"
            >
              Create Subfolder
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap pl-3">
        <ng-container *ngFor="let key of getFilteredKeys().slice(0, 3); let i = index">
          <div [ngSwitch]="key">
            <div *ngSwitchCase="'document_types'">
              <div
                class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                (click)="$event.stopPropagation(); toggleMenu('Type')"
                *ngIf="customFilters?.document_types?.length"
              >
                <div class="selected-filter-name">
                  Type
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ customFilters?.document_types?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedType.length < 4; else truncate">
                    {{ selectedType }}
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedType.slice(0, 3); let i = index">
                      {{ item }},
                    </ng-container>
                    <ng-container *ngIf="selectedType.length > 3">
                      <span [matTooltip]="selectedType" class="option-count">+{{ selectedType.length - 3 }} </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon style="font-size: 12px;" (click)="removeFilter('type')">close</mat-icon>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'author'">
              <div
                class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                (click)="$event.stopPropagation(); toggleMenu('Author')"
                *ngIf="customFilters?.author?.length"
              >
                <div class="selected-filter-name">
                  Author
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ customFilters?.author?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedAuthor.length < 4; else truncate">
                    {{ selectedAuthor }}
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedAuthor.slice(0, 3); let i = index">
                      {{ item }},
                    </ng-container>
                    <ng-container *ngIf="selectedAuthor.length > 3">
                      <span [matTooltip]="selectedAuthor" class="option-count">+{{ selectedAuthor.length - 3 }} </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon style="font-size: 12px;" (click)="removeFilter('author')">close</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div
          class="option-count"
          style="width: 21px; height: 21px; padding: 5px; margin-top: 16px;font-size: 10px;"
          *ngIf="getFilteredKeys().length > 3"
        >
          +{{ getFilteredKeys().length - 3 }}
        </div>
      </div>
      <div *ngIf="treeApiList?.length > 0; else noDocuments">
        <div class="folder-data">
          <table class="table table-hover">
            <thead class="bg-custom">
              <tr class="t-head">
                <th scope="col">TITLE</th>
                <th scope="col">TYPE</th>
                <th scope="col">AUTHOR</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of treeApiList">
                <td class="cursor-pointer" style="width: 50% !important;">
                  <span>
                    <img
                      *ngIf="item.type == 'document'"
                      class="mr-2 hw"
                      src="../../../assets/images/core/document.svg"
                    />
                    <img *ngIf="item.type == 'folder'" class="mr-2 hw" src="../../../assets/images/core/folder.svg" />
                    <img
                      *ngIf="item.type == 'presentation'"
                      class="mr-2 hw"
                      src="../../../assets/images/core/presentation.svg"
                    />
                    <img
                      *ngIf="item.type == 'spredsheet'"
                      class="mr-2 hw"
                      src="../../../assets/images/core/spredsheet.svg"
                    /> </span
                  ><span
                    class="truncated custom-text"
                    (click)="item.type == 'folder' ? setFolder(item.guid) : goToDocumentdetailspage(item.guid)"
                    title="{{ item.title }}"
                    >{{ item.title }}</span
                  >
                </td>
                <td>{{ item.type.charAt(0).toUpperCase() + item.type.slice(1) }}</td>
                <td>
                  <div class=" d-flex align-items-center">
                    <div class="initials-wrapper mr-1">
                      <div *ngIf="item.author" class="initials" [style.backgroundColor]="getColor(item.author)">
                        {{ getInitials(item.author) }}
                      </div>
                    </div>
                    {{ item.author }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-common-pagination
          [totalLength]="totalLength"
          [pageSize]="pageSize"
          [page]="page"
          [pageSizes]="pageSizes"
          (pageSizeChange)="changePageSize($event)"
          (pageChange)="pageChange($event)"
          (goToPageChange)="goToPageChange($event)"
        ></app-common-pagination>
      </div>
      <ng-template #noDocuments>
        <div class="row">
          <div class="col">
            <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
            <div class="text-empty-doc-list text-black-1" translate>No Documents</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
