import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { CustomsidenavComponent } from '@app/shared/modal/customsidenav/customsidenav.component';
import { UserService } from '@app/core/user.service';
import { CreateDocumentComponent } from '../create-document/create-document.component';
import { AddReadersComponent } from '../add-readers/add-readers.component';

@Component({
  selector: 'app-document-detail-page',
  templateUrl: './document-detail-page.component.html',
  styleUrls: ['./document-detail-page.component.scss']
})
export class DocumentDetailPageComponent implements OnInit {
  backend_error: any;
  TrainingGroupList: Array<any> = [];
  page = 1;
  statusDoc = stringInfo.Document_Details.Status;
  actions = stringInfo.Document_Details.Actions;
  publish = stringInfo.Document_Details.Publish_Date;
  accArray: any[] = [];
  created = stringInfo.Document_Details.Created_Date;
  added = stringInfo.Document_Details.Added;
  approver = stringInfo.Document_Details.Approver;
  editor1 = stringInfo.Document_Details.Editor;
  at = stringInfo.Document_Details.At;
  changeStatus = stringInfo.Document_Details.Change_Status;
  editComplete = stringInfo.Document_Details.Edit_Complete;
  complete = stringInfo.Document_Details.Complete_Edit;
  change = stringInfo.Document_Details.Change_Request;
  approved = stringInfo.Document_Details.Approved;
  docApproved = stringInfo.Document_Details.Doc_Approved;
  revoked = stringInfo.Document_Details.Revoked;
  docOn = stringInfo.Document_Details.Doc_On;
  docEditor = stringInfo.Document_Details.Doc_Editor;
  removed = stringInfo.Document_Details.Removed;
  publish1 = stringInfo.Document_Details.Publish;
  started = stringInfo.Document_Details.Started;
  editorsImg = stringInfo.Document_Details.Editors;
  approverImg = stringInfo.Document_Details.Approvers;
  guid: any;
  isOpen: boolean = true;
  isApproverOpen = true;
  historyOpen = false;
  guidSubscription: any;
  OrganisationID: string | null;
  detailInfo: any;
  groupName: string;
  error: any;
  details: any;
  version: any = [];
  title: any;
  status: any;
  editors: any;
  editor: any;
  editorsInitial: any;
  approvers: any;
  editorslength: any;
  approverlength: any;
  historyLog: any;
  description: any;
  createdDate: any;
  publishedDate: any;
  historyversion: any;
  versionKey: any;
  initiatedby: any;
  assignedTo: any;
  completed_by: any;
  allcompleteedit: any;
  changeRequestAssignTo: any;
  changeRequestInitiatedBy: any;
  invitedBy: any;
  invitedTo: any;
  docApprover: any;
  allApprover: any;
  revokerName: any;
  revokedBy: any;
  approverinitial: any;
  allcompleteInitials: string;
  approverRevoke: any;
  approverRevokedBy: any;
  draft: any;
  draftInitial: any;
  inviteEditorInitial: string;
  editingDoneInitial: string;
  reqChangeIntial: string;
  approveDocInitial: string;
  inviteapproverInitial: string;
  revokededitorInitial: string;
  revokeApproverInitial: string;
  alleditInitial: string;
  versionGuid: any;
  setVersion: boolean;
  author: any;
  publishdocInitial: string;
  templateInitial: string;
  editDoc: any;
  editDocInitiatedBy: any;
  editDocumentInitial: string;
  currentState: any;
  editingDoneStatus: any;
  prevState: any = [];
  reqChangeStatus: any;
  prevRequest: any;
  prevApprove: any;
  approveStatus: any;
  inviteApproverStatus: any;
  allEditCompleteStatus: any;
  editDocumentStatus: any;
  allApprovedStatus: any;
  publishDocStatus: any;
  editorRevoke: any;
  editorRevokedBy: any;
  Select_recurring_renewal: any;
  revokeEditorInitial: any;
  inviteApproverInitial: any;
  reqChangeInitiatedIntial: any;
  allowed_actions_list: any;
  authorName: any;
  allowedActionListLenght: any;
  userGuid: any;
  groupguid: any;
  comment: any;
  exportStatusMapper = {
    PENDING: 'Export',
    COMPLETED: 'Download Document',
    FAILED: 'Export (Failed)',
    INPROGRESS: 'Export'
  };
  history_data: Array<any> = [];
  document_version: any;
  loading = true;
  workspace: any;
  selectedVersion: any;
  docAuthor: any;
  authorInitial: any;
  firstVisit = true;
  private nameColors = new Map<string, string>();
  filteredActions: string[];

  constructor(
    private backendErrorService: BackendErrorService,
    public route: ActivatedRoute,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router,
    private sidenavService: SidenavService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.document_version = this.route.snapshot.params['document_version'];
    this.guid = this.route.snapshot.params['in_progress'];
    if (this.document_version) {
      this.document_version = this.route.snapshot.params['document_version'];
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.document_version);
    } else if (this.guid) {
      this.guid = this.route.snapshot.params['in_progress'];
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
    } else {
      this.workspace = this.route.snapshot.params['guid'];
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.workspace);
    }

    // this.getTrainingGroupList();
  }
  getInitials(name: string): string {
    const names = name.split(' ');
    let initials = '';
    for (let i = 0; i < names.length; i++) {
      if (names[i]) {
        initials += names[i][0].toUpperCase();
      }
    }
    return initials;
  }

  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  expand() {
    this.isOpen = true;
  }
  shrink() {
    this.isOpen = false;
  }
  expandApprover() {
    this.isApproverOpen = true;
  }
  shrinkApprover() {
    this.isApproverOpen = false;
  }
  expandHistory() {
    this.historyOpen = true;
  }
  shrinkHistory() {
    this.historyOpen = false;
  }
  getDocumentDetailPageInfo(OrganisationID: any, guid: any): void {
    this.documentsService.getDocumentDetailPage(OrganisationID, guid).subscribe(
      data => {
        this.history_data = [];
        this.loading = false;
        this.detailInfo = data;
        this.title = data.title;
        this.status = data.status.replace(/_/g, ' ');
        this.version = data.versions_list;
        this.editors = data.editors;
        this.approvers = data.approvers;
        this.editorslength = data.editors.length;
        this.approverlength = data.approvers.length;
        this.historyLog = data.history_log;
        this.description = data.description;
        this.historyversion = data.version;
        this.filteredActions = this.detailInfo['allowed_actions_list'].filter(
          (action: any) =>
            action !== 'Manage Editors' &&
            action !== 'Manage Approvers' &&
            action !== 'Edit Document Details' &&
            action !== 'View Editors' &&
            action !== 'View Approvers' &&
            action !== 'Edit Document'
        );
        if (data.renewal_string.length > 0) {
          this.Select_recurring_renewal = data.renewal_string[0].renewal_string;

          console.log(this.Select_recurring_renewal);
        }
        if (data.created_timestamp.length > 0) {
          this.createdDate = data.created_timestamp;
        } else {
          this.publishedDate = data.published_on_timestamp;
        }

        this.allowed_actions_list = data.allowed_actions_list;
        this.allowedActionListLenght = this.allowed_actions_list.length;
        for (let i = 0; i < data.history_log.length; i++) {
          let currentState = '';
          let previousState = '';
          let showStateChange = false;

          if (i > 0) {
            currentState = this.historyLog[i].action_log.display_status;
            previousState = this.historyLog[i - 1].action_log.display_status;
            this.historyLog[i].previousState = previousState;
            this.historyLog[i].currentState = currentState;
          }

          if (currentState !== previousState) {
            // console.log('state changed from ' + previousState + ' -> ' + currentState);
            showStateChange = true;
          }

          if (this.historyLog[i].action_type == 'new_draft') {
            this.draft = this.historyLog[i]['action_log']['author'];
            var draftInit = String(this.draft).match(/\b(\w)/g);
            this.draftInitial = draftInit.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              created_by: this.draft,
              created_by_Initial: this.draftInitial
            });
          } else if (this.historyLog[i].action_type == 'invite_editor') {
            this.assignedTo = this.historyLog[i]['action_log']['assigned_to']['names'];
            this.initiatedby = data.history_log[i].action_log.initiated_by.name;
            var inviteEditorIntial = String(this.initiatedby).match(/\b(\w)/g);
            this.inviteEditorInitial = inviteEditorIntial.join('').toUpperCase();
            this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              assignedTo: this.assignedTo,
              initiatedby: this.initiatedby,
              inviteEditorInitial: this.inviteEditorInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'invite_approver') {
            this.assignedTo = this.historyLog[i]['action_log']['assigned_to']['names'];
            this.initiatedby = data.history_log[i].action_log.initiated_by.name;
            var inviteApproverIntial = String(this.initiatedby).match(/\b(\w)/g);
            this.inviteApproverInitial = inviteApproverIntial.join('').toUpperCase();
            this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              assignedTo: this.assignedTo,
              initiatedby: this.initiatedby,
              inviteApproverInitial: this.inviteApproverInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'mark_editing_done') {
            this.completed_by = this.historyLog[i]['action_log']['completed_by']['name'];
            var editingDone = String(this.completed_by).match(/\b(\w)/g);
            this.editingDoneInitial = editingDone.join('').toUpperCase();
            this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              completed_by: this.completed_by,
              editingDoneInitial: this.editingDoneInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'requested_change') {
            //Handle Invite Editor For Request Change
            if (data.history_log[i].action_log.type == 'create_collaboration_task') {
              this.changeRequestAssignTo = this.historyLog[i]['action_log']['assigned_to']['names'];
              this.changeRequestInitiatedBy = data.history_log[i].action_log.initiated_by.name;
              var reqChange = String(this.changeRequestAssignTo).match(/\b(\w)/g);
              this.reqChangeIntial = reqChange.join('').toUpperCase();
              var reqchangeinitiated = String(this.changeRequestInitiatedBy).match(/\b(\w)/g);
              this.reqChangeInitiatedIntial = reqchangeinitiated.join('').toUpperCase();
              this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];

              this.history_data.push({
                history: this.historyLog[i],
                changeRequestInitiatedBy: this.changeRequestInitiatedBy,
                changeRequestAssignTo: this.changeRequestAssignTo,
                reqChangeIntial: this.reqChangeIntial,
                reqChangeInitiatedIntial: this.reqChangeInitiatedIntial,
                comment: this.comment
              });
            } else {
            }
          } else if (this.historyLog[i].action_type == 'revoke_approver') {
            this.approverRevoke = this.historyLog[i]['action_log']['revoked_details']['name'];
            this.approverRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
            var revokeApprover = String(this.approverRevokedBy).match(/\b(\w)/g);
            this.revokeApproverInitial = revokeApprover.join('').toUpperCase();
            this.comment = this.historyLog[i]['action_log']['revoked_details']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              approverRevoke: this.approverRevoke,
              approverRevokedBy: this.approverRevokedBy,
              revokeApproverInitial: this.revokeApproverInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'revoke_editor') {
            this.editorRevoke = this.historyLog[i]['action_log']['revoked_details']['name'];
            this.editorRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
            var revokeEditor = String(this.editorRevokedBy).match(/\b(\w)/g);
            this.revokeEditorInitial = revokeEditor.join('').toUpperCase();
            this.comment = this.historyLog[i]['action_log']['revoked_details']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              editorRevoke: this.editorRevoke,
              editorRevokedBy: this.editorRevokedBy,
              revokeEditorInitial: this.revokeEditorInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'all_completed_edit') {
            this.allcompleteedit = this.historyLog[i]['action_log']['names'];
            var alledit = String(this.allcompleteedit).match(/\b(\w)/g);
            this.alleditInitial = alledit.join('').toUpperCase();
            this.allEditCompleteStatus = this.historyLog[i]['action_log']['display_status'];
            this.history_data.push({
              history: this.historyLog[i],
              allcompleteedit: this.allcompleteedit,
              alleditInitial: this.alleditInitial,
              allEditCompleteStatus: this.allEditCompleteStatus
            });
          } else if (this.historyLog[i].action_type == 'edit_document') {
            this.editDoc = this.historyLog[i]['action_log']['assigned_to']['names'];
            this.editDocInitiatedBy = data.history_log[i].action_log.initiated_by.name;
            var editDocument = String(this.editDocInitiatedBy).match(/\b(\w)/g);
            this.editDocumentInitial = editDocument.join('').toUpperCase();
            this.editDocumentStatus = this.historyLog[i]['action_log']['display_status'];
            this.history_data.push({
              history: this.historyLog[i],
              editDoc: this.editDoc,
              editDocInitiatedBy: this.editDocInitiatedBy,
              editDocumentInitial: this.editDocumentInitial,
              editDocumentStatus: this.editDocumentStatus
            });
          } else if (this.historyLog[i].action_type == 'approve_document') {
            this.docApprover = data.history_log[i].action_log.completed_by.name;
            var approveDoc = String(this.docApprover).match(/\b(\w)/g);
            this.approveDocInitial = approveDoc.join('').toUpperCase();
            this.approveStatus = this.historyLog[i]['action_log']['display_status'];
            this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
            this.history_data.push({
              history: this.historyLog[i],
              docApprover: this.docApprover,
              approveDocInitial: this.approveDocInitial,
              approveStatus: this.approveStatus,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'all_approved') {
            this.allApprover = this.historyLog[i]['action_log']['names'];
            var initials1 = String(this.allApprover).match(/\b(\w)/g);
            this.approverinitial = initials1.join('').toUpperCase();
            this.allApprovedStatus = this.historyLog[i]['action_log']['display_status'];
            this.history_data.push({
              history: this.historyLog[i],
              allApprover: this.allApprover,
              approverinitial: this.approverinitial,
              allApprovedStatus: this.allApprovedStatus
            });
          } else if (this.historyLog[i].action_type == 'publish_document') {
            this.author = this.historyLog[i]['action_log']['author'];
            var publishdoc = String(this.author).match(/\b(\w)/g);
            this.publishdocInitial = publishdoc.join('').toUpperCase();
            this.publishDocStatus = this.historyLog[i]['action_log']['display_status'];
            this.history_data.push({
              history: this.historyLog[i],
              published_by: this.author,
              publishdocInitial: this.publishdocInitial,
              publishDocStatus: this.publishDocStatus
            });
          } else if (this.historyLog[i].action_type == 'new_template') {
            this.author = this.historyLog[i]['action_log']['author'];
            var templatedoc = String(this.author).match(/\b(\w)/g);
            this.templateInitial = templatedoc.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              published_by: this.author,
              templateInitial: this.templateInitial
            });
          } else if (
            this.historyLog[i].action_type == 'document_unarchived' ||
            this.historyLog[i].action_type == 'document_archived'
          ) {
            this.docAuthor = data.history_log[i].action_log.author;
            var approveDoc = String(this.docAuthor).match(/\b(\w)/g);
            this.authorInitial = approveDoc.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              docAuthor: this.docAuthor,
              authorInitial: this.authorInitial
            });
          }
        }

        if (this.detailInfo) {
          var initials = this.detailInfo['created_by']['name'].match(/\b(\w)/g);
          this.details = initials.join('').toUpperCase();
          this.authorName = this.detailInfo['created_by']['name'];
          this.editors.forEach((editor: { [x: string]: any }) => {
            var initials = editor['user']['name'].match(/\b(\w)/g);
            editor['initials'] = initials.join('').toUpperCase();
          });

          this.approvers.forEach((approver: { [x: string]: any }) => {
            var initials = approver['user']['name'].match(/\b(\w)/g);
            approver['initials'] = initials.join('').toUpperCase();
          });
        }
        this.route.queryParams.subscribe(res => {
          if (res['open-reader-modal'] == 'true' && this.firstVisit) {
            this.OpenAddReadersModal(this.detailInfo, this.detailInfo['guid']);
          }
        });
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
  get editDocumentAccess() {
    return this.allowed_actions_list.includes('Edit Document');
  }
  get editDetailsAccess() {
    return this.allowed_actions_list.includes('Edit Document Details');
  }
  get editorAccess() {
    return this.allowed_actions_list.includes('Manage Editors');
  }
  get editorsActionAccess() {
    return this.allowed_actions_list.includes('Manage Editors') || this.allowed_actions_list.includes('Request Change');
  }
  get approveraActionAccess() {
    return (
      this.allowed_actions_list.includes('Manage Approvers') || this.allowed_actions_list.includes('Request Change')
    );
  }
  get approverAccess() {
    return this.allowed_actions_list.includes('Manage Approvers');
  }
  changeVersion(version1: any) {
    this.historyversion = '';
    this.versionKey = version1;
    this.history_data = [];
    for (let i = 0; i < this.version.length; i++) {
      if (this.versionKey == this.version[i].version) {
        this.versionGuid = this.version[i].guid;
        break;
      }
    }
    this.getDocumentDetailPageInfo(this.OrganisationID, this.versionGuid);
  }
  redirectToDocumentManagement(): void {
    this.router.navigateByUrl('/organisation/documents/attention-required');
  }
  // Manage Editors code

  openEditorApproverModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string, type = '') {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: type == 'Manage Approvers' ? 'Manage Approvers' : 'Manage Editors',
        modalType: type == 'Manage Approvers' ? 'Manage Approvers' : 'Manage Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        DocCreatedBy: DocCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          if (type == 'Manage Approvers') {
            this.translate
              .get('Document_Invite_Approver.invite_approver_toaster_message.invite_approver')
              .subscribe(res => {
                this.toaster.showSuccess(res);
                this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
              });
          }
          if (type == 'Manage Editors') {
            this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
              this.toaster.showSuccess(res);
              this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
            });
          }
        }
      });
  }
  // Invite Editors for Request Change code
  InviteEditorsForChangeReqOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Invite Editors for Request Change',
        modalType: 'Invite Editors for Request Change',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          console.log('Cancelled.');
        }
      });
  }

  // Invite Approvers Code
  InviteApproverOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Manage Approvers',
        modalType: 'Manage Approvers',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.translate
            .get('Document_Invite_Approver.invite_approver_toaster_message.invite_approver')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          console.log('Cancelled.');
        }
      });
  }

  // Mark Edit Done Code
  MarkEditDoneOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Mark Editing as Done',
        modalType: 'Marked Editing as Done',
        message: 'Are you sure you want to mark the document <b> ' + docTitle + ' </b> as editing done?',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.translate
            .get('Mark_Editing_Done.mark_edit_done_toaster_message.mark_edit_done_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Approve Changes Code
  ApproveChangesOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Changes',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.translate
            .get('Approve_Changes.approve_changes_toaster_message.approve_changes_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Approvers Code
  ViewApproversOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Approvers',
        modalType: 'View Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Editors Code
  ViewEditorsOpenModal(DocumentGuid: string, docTitle: string, type: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Editors',
        modalType: 'View Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Publish Document Code
  openPublishDocumentForm(DocumentGuid: string, DocName: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Publish Documents',
        modalType: 'Publish Documents',
        DocumentGuid: DocumentGuid,
        message: 'Are you sure you want to publish the document <b>' + DocName + '</b> ?',
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.translate
            .get('Publish_Document.publish_document_toaster_message.publish_document_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  OpenAddReadersModal(DocumentObj: any, DocumentVersion: any): void {
    const readerEmails = DocumentObj.readers.map((x: any) => x.email);
    this.sidenavService
      .open(AddReadersComponent, {
        title: 'Add Readers',
        modalType: 'Add Readers',
        guid: DocumentObj.document_guid,
        readerEmails: readerEmails,
        DocumentVersion: DocumentVersion,
        requestedAccess: DocumentObj.requested_access
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, DocumentVersion);
        } else {
          this.getDocumentDetailPageInfo(this.OrganisationID, DocumentVersion);
          console.log('Cancelled.');
        }
        this.firstVisit = false;
      });
  }

  onNavigate(docGuid: string, DocumentVersion: string) {
    this.documentsService.getOrganizationDocumentEditURl(this.OrganisationID, docGuid, '').subscribe(
      data => {
        this.OpenDocument(data.document_store_identifier);
        console.log(DocumentVersion);
        this.getDocumentDetailPageInfo(this.OrganisationID, DocumentVersion);
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  OpenDocument(url: string) {
    window.open(url, '_blank');
  }

  NewVersionOpenModal(DocumentGuid: string, DocumentVersion: string, docTitle: string): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Create New Version',
        modalType: 'Create New Version',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        DocumentVersion: DocumentVersion
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getDocumentDetailPageInfo(this.OrganisationID, this.document_version);
      });
  }
  exportDocument(doc_version_guid: string, data = {}) {
    let payload = {
      document_type: data['document_type'],
      document_id: doc_version_guid,
      owner: data['created_by']['name']
    };
    this.documentsService.exportDocument(this.OrganisationID, data['guid'], payload).subscribe(
      data => {
        this.getDocumentDetailPageInfo(this.OrganisationID, this.document_version);
        this.toaster.showSuccess('Export is in progress');
      },
      error => {
        this.toaster.showError(error.error);
      }
    );
  }

  downloadDocument(url: string) {
    window.open(url, '_blank');
  }

  getClickEvents(
    action: string,
    DocGuid: string,
    createdBy: string,
    docTitle: string,
    url: string,
    DocumentVersion: string,
    docInprogressGuid: string,
    Publish: any
  ): void {
    console.log();
    if (action == 'Edit Document') {
      this.onNavigate(DocGuid, Publish['guid']);
    } else if (action == 'Manage Editors') {
      this.openEditorApproverModal(DocGuid, createdBy, docTitle, action);
    } else if (action == 'View Editors') {
      this.ViewEditorsOpenModal(DocGuid, docTitle, action);
    } else if (action == 'Complete Edit') {
      this.MarkEditDoneOpenModal(DocGuid, docTitle);
    } else if (action == 'Manage Approvers') {
      this.openEditorApproverModal(DocGuid, createdBy, docTitle, action);
    } else if (action == 'Request Change') {
      this.InviteEditorsForChangeReqOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'View Approvers') {
      this.ViewApproversOpenModal(DocGuid, docTitle);
    } else if (action == 'Approve Document') {
      this.ApproveChangesOpenModal(DocGuid, docTitle);
    } else if (action == 'Publish Document') {
      this.openPublishDocumentForm(DocGuid, docTitle);
    } else if (action == 'Create New Version') {
      this.NewVersionOpenModal(DocGuid, DocumentVersion, docTitle);
    } else if (action == 'View Document') {
      this.OpenDocument(url);
    } else if (action == 'Edit Document Details') {
      this.openCreateDocument(docInprogressGuid);
    } else if (action == 'Add Readers') {
      this.OpenAddReadersModal(Publish, Publish['guid']);
    } else if (action == 'PENDING' || action == 'INPROGRESS' || action == 'FAILED') {
      this.exportDocument(Publish['document_guid'], Publish);
    } else if (action == 'COMPLETED') {
      this.downloadDocument(Publish['exported_doc_store_id']);
    } else if (action == 'Add Readers') {
      this.OpenAddReadersModal(Publish, Publish['document_version']);
    } else if (action == 'Archive Document') {
      if (Publish.status === 'published') {
        this.archivePublishedDocument(docInprogressGuid, DocGuid);
      } else {
        this.archiveDocument(docInprogressGuid, DocGuid);
      }
    }
  }
  openCreateDocument(docInprogressGuid?: any) {
    this.documentsService.editDocumentGuid.next(docInprogressGuid);
    this.sidenavService
      .open(CreateDocumentComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.result) {
          console.log(result, 'result');
          let msg = docInprogressGuid
            ? `Document ${result.result.title} edited successfully`
            : `Document ${result.result.title} created successfully`;

          this.translate.get(msg).subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
        }
      });
  }
  archiveDocument(docInprogressGuid: string, guid: string) {
    this.documentsService.archiveDocument(this.OrganisationID, docInprogressGuid).subscribe(
      data => {
        history.back();
        this.translate.get(data.message).subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        this.toaster.showError(this.error);
      }
    );
  }

  archivePublishedDocument(docInprogressGuid: string, guid: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Archive Document',
        modalType: 'Archive Document',
        DocumentGuid: docInprogressGuid,
        message:
          'The document will not be available for future training campaigns. Are you sure you want to archive the document?'
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate) {
          history.back();
        }
      });
  }

  openRevokeEditorForm(DocumentGuid: string, guid: string, user: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Remove Editor',
        message: 'Are you sure you want to <b>' + user + '</b> as editor ?',
        modalType: 'Revoke Editor',
        guid: guid,
        DocumentGuid: DocumentGuid,
        centered: true
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          this.translate.get('Revoke_Editor.revoke_editor_toaster_message.revoke_editor_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  removeApprover(DocumentGuid: string, guid: string, user: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Remove Approver',
        message: 'Are you sure you want to remove <b>' + user.name + '</b> from approvers?',
        modalType: 'Revoke Approver',
        guid: guid,
        DocumentGuid: DocumentGuid,
        centered: true
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          let msg = 'Revoke_Approver.revoke_approver_toaster_message.revoke_approver_submitted';
          this.translate.get(msg).subscribe(res => {
            this.toaster.showSuccess(res);
            this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openReassignEditorApproverForm(
    DocumentGuid: string,
    guid: string,
    userEmail: string,
    user: string,
    due_date: any,
    role: string
  ): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Reassign Editor',
        modalType: 'Reassign Collaborator',
        DocumentGuid: DocumentGuid,
        guid: guid,
        userEmail: userEmail,
        user: user,
        due_date: due_date,
        role: role
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, result.result.in_progress);
          if (role === 'approver') {
            this.translate
              .get('Reassign_Approver.reassign_approver_toaster_message.reassign_approver_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else if (role === 'editor') {
            this.translate
              .get('Restore_Editor.restore_editor_toaster_message.restore_editor_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openChangeDueFrom(
    DocumentGuid: string,
    guid: string,
    userEmail: string,
    user: string,
    start_message: string,
    due_date: any,
    role: string
  ): void {
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Edit Collaboration',
        modalType: 'Edit Due Date',
        DocumentGuid: DocumentGuid,
        guid: guid,
        userEmail: userEmail,
        user: user,
        start_message: start_message,
        due_date: due_date,
        role: role
      })

      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getDocumentDetailPageInfo(this.OrganisationID, result.result.in_progress);
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
