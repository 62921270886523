import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WizardService } from '@app/home/wizard.services';
import { OrgAdminShellHeaderComponent } from '@app/shells/orgAdminshell/header/orgAdminshellHeader.component';

@Component({
  selector: 'app-program-configuration',
  templateUrl: './program-configuration.component.html',
  styleUrls: ['./program-configuration.component.scss']
})
export class ProgramConfigurationComponent implements OnInit, OnDestroy {
  programConfigform: FormGroup;
  guidSubscription: Subscription;
  organisationID: string = '';
  loading = false;
  isPanelOpen: boolean = false;
  chips: { guid: string; name: string; selected: boolean }[] = [];
  orgTypes: { guid: string; title: string; tooltip?: string }[] = [];
  selectedEmployeeAmt = '';
  error: string = '';
  orgName = '';
  userName: any = '';

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      this.getOrganisationType();
      const credentialsString = localStorage.getItem('credentials');
      const credentials = JSON.parse(credentialsString);
      this.userName = credentials.user.first_name.charAt(0).toUpperCase() + credentials.user.first_name.slice(1);
    });
  }
  constructor(
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private orgDashboardService: OrganisationDashboardService,
    private router: Router,
    private modalService: ModalService,
    private wizard: WizardService
  ) {
    this.programConfigform = formBuilder.group({
      org_name: ['', Validators.required],
      cmp_programs: [[]]
    });
  }

  toggleSelected(chip: any) {
    chip.selected = !chip.selected;
  }
  onChipClick(event: Event, chip: any): void {
    if (chip.name?.toLowerCase() !== 'HIPAA'?.toLowerCase()) {
      this.toggleSelected(chip);
    } else {
      event.preventDefault();
    }
  }
  selectOpened(opened: boolean) {
    this.isPanelOpen = opened;
  }
  getTooltip(selectedOrgType: string): string {
    const selectedItem = this.orgTypes.find(item => item.guid === selectedOrgType);
    return selectedItem ? selectedItem.tooltip : '';
  }
  getselectedTitle(selectedOrgType: string) {
    const selectedItem = this.orgTypes.find(item => item.guid === selectedOrgType);
    return selectedItem ? selectedItem.title : '';
  }

  get checkChipsSelected() {
    return this.chips?.some(item => item?.selected);
  }
  getOrganisationType() {
    this.loading = true;
    this.orgDashboardService.getProfileConfiguration(this.organisationID).subscribe(
      res => {
        this.orgTypes = res['data'];
        res['data'].compliance_programs.forEach((item: any) => {
          item['tooltip'] = item.title;
          if (item.name == 'HIPAA') {
            item['selected'] = true;
          } else {
            item['selected'] = false;
          }
        });
        this.chips = res['data'].compliance_programs;
        this.programConfigform.patchValue({
          org_name: res['data'].organization_details?.name
            ? res['data'].organization_details?.name.charAt(0).toUpperCase() +
              res['data'].organization_details?.name.slice(1)
            : ''
        });
        this.orgName = res['data'].organization_details?.name
          ? res['data'].organization_details?.name.charAt(0).toUpperCase() +
            res['data'].organization_details?.name.slice(1)
          : '';
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  saveForm() {
    if (this.programConfigform.invalid || !this.checkChipsSelected) return;
    this.programConfigform.patchValue({
      cmp_programs: this.chips.filter(item => item.selected).map(item => item.guid)
    });
    this.loading = true;
    let payload = { ...this.programConfigform.value };
    if (this.programConfigform.value.org_name.trim().toLowerCase() == this.orgName.trim().toLowerCase()) {
      delete payload['org_name'];
    }

    this.orgDashboardService.saveProfileConfiguration(this.organisationID, payload).subscribe(
      res => {
        this.loading = false;
        localStorage.setItem('isProgramConfigCompleted', 'true');
        this.wizard.getOrganizationDetails(this.organisationID).subscribe(data => {
          this.orgName = data['name'];
          localStorage.setItem('org_password_based_login', data['org_password_based_login']);
          localStorage.setItem('domain', data['domain']);
          localStorage.setItem('compliance_programs', JSON.stringify(data['compliance_programs']));
          localStorage.setItem('org_type', JSON.stringify(data['org_type']));
        });
        if (payload.org_name) {
          this.wizard.getUserOrganizations().subscribe(data => {
            this.wizard.userOrganizations.next(data);
          });
        }

        this.router.navigateByUrl('/organization-assessment');
      },
      error => {
        this.loading = false;
        let errorkey = Object.keys(error.error)[0];
        this.error = error.error[errorkey][errorkey] ? error.error[errorkey][errorkey] : error.error[errorkey]?.error;
      }
    );
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
